import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { ContactsPath, VIEW_URL } from "../../utils/paths";

const ContactPage = () => {
  const latitude = -2.1580133870798646;
  const longitude = -79.89914417266847;
  const position = [latitude, longitude];
  const mapStyles = { height: "400px", width: "100%" };

  return (
    <Container className="mt-5">
      <Helmet>
        <title>Contactanos</title>
        <link rel="canonical" href={`${VIEW_URL}${ContactsPath}`} />
        <meta
          name="description"
          content="Contáctanos en nuestra agencia de viajes en Guayaquil. Estamos aquí para ayudarte a planificar tus próximas aventuras con tecnología avanzada y servicio personalizado."
        />
      </Helmet>
      <Row>
        <Col md={6} className="mb-5">
          <h1>Contactos de la Agencia</h1>
          <MapContainer center={position} zoom={15} style={mapStyles}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>Ubicación de la agencia</Popup>
            </Marker>
          </MapContainer>
        </Col>

        <Col md={6}>
          <Container className="mb-5 mt-2">
            <h2>Contactos Generales</h2>
            <p>
              <strong>Dirección:</strong> Guayaquil, Edificio Rafermartz,
              Kennedy Norte, Av. Luis Orrantia Cornejo 22-23 y, Victor Hugo
              Sicouret Mz 109. Oficina 3A.
            </p>
            <p>
              <strong>Teléfono:</strong> +593 98 647 8477
            </p>
            <p>
              <strong>Email:</strong> info@epicjourneyagency.com
            </p>
          </Container>

          <Container className="mb-5">
            <h2>Contactos de Emergencia</h2>
            <p>
              <strong>Número:</strong> +593 98 647 8477
            </p>
          </Container>

          <Container className="mb-5">
            <h2>Horarios de Atención</h2>
            <p>
              <strong>Lunes a Viernes:</strong> 9:00 AM - 6:00 PM
            </p>
            <p>
              <strong>Sábado:</strong> 10:00 AM - 2:00 PM
            </p>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;
