import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import {
  createCarRentalAction,
  updateCarRentalAction,
} from "../../../../redux/ducks/agency/carRentalDuck";
import { validateDateGTToday } from "../../../../utils/validators";

const CarRentalForm = ({
  updateCarRentalAPI,
  createCarRentalAPI,
  carRentalState,
  carTypes,
  agencyRuc,
  dataInput = null,
  packageServiceID = null,
}) => {
  const { created, updated } = carRentalState;
  const updateTask = dataInput !== null;
  const blockRestrictedFields = updateTask;
  const blockAllFields = (created || updated) !== null;
  const buttonName = updateTask ? "Actualizar" : "Guardar";
  const commonData = { agency: agencyRuc };
  const defaultData = {
    cost: 0,
    operator: "",
    manager_name: "",
    delivery_date: "",
    return_date: "",
    car_type: "",
    capacity: "",
    ...commonData,
  };
  const [data, setData] = useState(defaultData);
  const [validated, setValidated] = useState(false);
  const [dataSended, setDataSended] = useState(false);

  const validatedFiedls = {
    operator: data.operator !== "",
    manager_name: data.manager_name !== "",
    delivery_date: validateDateGTToday(data.delivery_date) || updateTask,
    return_date: validateDateGTToday(data.return_date) || updateTask,
    car_type: data.car_type !== "",
    capacity: data.capacity > 0,
    reservation: Boolean(data.reservation) || updateTask,
    cost: data.cost >= 0,
  };
  const isFormValidated = Object.values(validatedFiedls).every((val) => val);

  useEffect(() => {
    if (updateTask) {
      const tmpData = { ...dataInput, ...commonData };
      tmpData.car_type = tmpData.car_type.id;
      delete tmpData.reservation;
      setData(tmpData);
    }
  }, [dataInput]);

  const handleAddReservationFile = (event) => {
    const file = event.target.files[0];
    if (file) setData({ ...data, reservation: file });
  };

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const sendData = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDataSended(true);

    const form = event.currentTarget;
    const formIsValid = form.checkValidity();
    if (!(isFormValidated && formIsValid) && !updateTask) return;
    setValidated(true);

    if (updateTask) updateCarRentalAPI(data);
    else createCarRentalAPI(data, packageServiceID);
  };

  return (
    <Form noValidate validated={validated} onSubmit={sendData}>
      <Container>
        <Row>
          <Col sm={12} lg={3} className="mb-4">
            <Form.Group controlId="formOperator">
              <Form.Label>Operador</Form.Label>
              <Form.Control
                name="operator"
                value={data.operator}
                onChange={handleInputChange}
                disabled={blockRestrictedFields || blockAllFields}
                isInvalid={dataSended && !validatedFiedls.operator}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={3} className="mb-4">
            <Form.Group controlId="formManagerName">
              <Form.Label>Propietario</Form.Label>
              <Form.Control
                name="manager_name"
                value={data.manager_name}
                onChange={handleInputChange}
                disabled={blockRestrictedFields || blockAllFields}
                isInvalid={dataSended && !validatedFiedls.manager_name}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formCapacity">
              <Form.Label>Capacidad</Form.Label>
              <Form.Control
                type="number"
                disabled={blockAllFields}
                onChange={handleInputChange}
                value={data.capacity}
                name="capacity"
                isInvalid={dataSended && !validatedFiedls.capacity}
                required
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formLuggageType">
              <Form.Label>Tipo de Vehículo</Form.Label>
              <Form.Select
                disabled={blockAllFields}
                onChange={handleInputChange}
                value={data.car_type}
                name="car_type"
                isInvalid={dataSended && !validatedFiedls.car_type}
                required
              >
                <option>Seleccione</option>
                {carTypes.map((luggage) => (
                  <option key={luggage.id} value={luggage.id}>
                    {luggage.branch}, {luggage.model}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formDeliveryDate">
              <Form.Label>Fecha Entrega</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                onChange={handleInputChange}
                value={data.delivery_date}
                name="delivery_date"
                isInvalid={dataSended && !validatedFiedls.delivery_date}
              />
              <Form.Control.Feedback type="invalid">
                La fecha debe ser mayor o igual al día de hoy
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formReturnDate">
              <Form.Label>Fecha Retorno</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                onChange={handleInputChange}
                value={data.return_date}
                name="return_date"
                isInvalid={dataSended && !validatedFiedls.return_date}
              />
              <Form.Control.Feedback type="invalid">
                La fecha debe ser mayor o igual al día de hoy
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={12} lg={6} className="mb-4 mb-lg-2">
            <Form.Group controlId="formReservation">
              <Form.Label>Reserva</Form.Label>
              <Form.Control
                onChange={handleAddReservationFile}
                disabled={blockAllFields}
                type="file"
                required
                isInvalid={dataSended && !validatedFiedls.reservation}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4 mb-lg-2">
            <Form.Group controlId="formCost">
              <Form.Label>Costo</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  disabled={blockAllFields}
                  type="number"
                  onChange={handleInputChange}
                  value={data.cost}
                  name="cost"
                  isInvalid={dataSended && !validatedFiedls.cost}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} lg={{ offset: 2, span: 2 }} className="mt-4">
            <Button
              className="w-100"
              variant="warning"
              type="submit"
              disabled={blockAllFields}
            >
              {buttonName}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  carRentalState: state.carRental,
  carTypes: state.carType.all,
  agencyRuc: state.agency.ruc,
});

const actions = {
  createCarRentalAPI: createCarRentalAction,
  updateCarRentalAPI: updateCarRentalAction,
};

export default connect(mapStateToProps, actions)(CarRentalForm);
