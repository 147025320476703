import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { convertDatetimeString } from "../../../../utils/utils";
import {
  createHostingAction,
  updateHostingAction,
} from "../../../../redux/ducks/agency/hostingDuck";
import {
  validateDateGTToday,
  validateLocationStr,
} from "../../../../utils/validators";

/* eslint-disable camelcase */
const HostingForm = ({
  updateHostingAPI,
  createHostingAPI,
  hostingState,
  foodPlans,
  agencyRuc,
  dataInput = null,
  packageServiceID = null,
}) => {
  const { created, updated } = hostingState;
  const updateTask = dataInput !== null;
  const blockRestrictedFields = updateTask;
  const blockAllFields = (created || updated) !== null;
  const buttonName = updateTask ? "Actualizar" : "Guardar";
  const commonData = { agency: agencyRuc };
  const defaultData = {
    cost: 0,
    name: "",
    address: "",
    check_in: "",
    check_in_hour: "",
    check_out: "",
    check_out_hour: "",
    food_plan: "",
    location: "",
    ...commonData,
  };
  const [data, setData] = useState(defaultData);
  const [validated, setValidated] = useState(false);
  const [dataSended, setDataSended] = useState(false);

  const validatedFiedls = {
    name: data.name !== "",
    address: data.address !== "",
    check_in: validateDateGTToday(data.check_in) || updateTask,
    check_out: validateDateGTToday(data.check_out) || updateTask,
    check_in_hour: data.check_in_hour !== "",
    check_out_hour: data.check_out_hour !== "",
    food_plan: data.food_plan !== "",
    location: validateLocationStr(data.location),
    reservation: Boolean(data.reservation) || updateTask,
    cost: data.cost >= 0,
  };
  const isFormValidated = Object.values(validatedFiedls).every((val) => val);

  useEffect(() => {
    if (updateTask) {
      const tmpData = { ...dataInput, ...commonData };
      const checkIn = convertDatetimeString(tmpData.check_in);
      const checkOut = convertDatetimeString(tmpData.check_out);
      tmpData.check_in = checkIn.dateInput;
      tmpData.check_out = checkOut.dateInput;
      tmpData.check_in_hour = checkIn.time;
      tmpData.check_out_hour = checkOut.time;

      tmpData.location_id = tmpData.location.id;
      tmpData.location = `${tmpData.location.latitude},${tmpData.location.longitude}`;
      tmpData.food_plan = tmpData.food_plan.id;
      delete tmpData.reservation;
      setData(tmpData);
    }
  }, [dataInput]);

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleAddReservationFile = (event) => {
    const file = event.target.files[0];
    if (file) setData({ ...data, reservation: file });
  };

  const sendData = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDataSended(true);

    const form = event.currentTarget;
    const formIsValid = form.checkValidity();
    if (!(isFormValidated && formIsValid) && !updateTask) return;
    setValidated(true);

    const {
      check_in,
      check_in_hour,
      check_out,
      check_out_hour,
      location,
      location_id, // only for updating step
      ...dataStructured
    } = data;
    dataStructured.check_in = `${check_in} ${check_in_hour}`;
    dataStructured.check_out = `${check_out} ${check_out_hour}`;

    if (updateTask) updateHostingAPI(dataStructured, location, location_id);
    else createHostingAPI(dataStructured, location, packageServiceID);
  };

  return (
    <Form noValidate validated={validated} onSubmit={sendData}>
      <Container>
        <Row>
          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                name="name"
                value={data.name}
                onChange={handleInputChange}
                disabled={blockRestrictedFields || blockAllFields}
                isInvalid={dataSended && !validatedFiedls.name}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={6} className="mb-4">
            <Form.Group controlId="formAddress">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                name="address"
                as="textarea"
                value={data.address}
                onChange={handleInputChange}
                disabled={blockRestrictedFields || blockAllFields}
                isInvalid={dataSended && !validatedFiedls.address}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formFoodPlan">
              <Form.Label>Plan Alimenticio</Form.Label>
              <Form.Select
                disabled={blockAllFields}
                onChange={handleInputChange}
                value={data.food_plan}
                name="food_plan"
                isInvalid={dataSended && !validatedFiedls.food_plan}
                required
              >
                <option>Seleccione</option>
                {foodPlans.map((foodPlan) => (
                  <option key={foodPlan.id} value={foodPlan.id}>
                    {foodPlan.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formLocation">
              <Form.Label>Ubicación</Form.Label>
              <Form.Control
                disabled={blockRestrictedFields || blockAllFields}
                placeholder="Latitud, Longuitud"
                name="location"
                onChange={handleInputChange}
                value={data.location}
                isInvalid={dataSended && !validatedFiedls.location}
              />
              <Form.Control.Feedback type="invalid">
                La ubicación deber ser en el formato: Latitud, Longuitud
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formCheckInDate">
              <Form.Label>Fecha Check In</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                name="check_in"
                onChange={handleInputChange}
                value={data.check_in}
                isInvalid={dataSended && !validatedFiedls.check_in}
              />
              <Form.Control.Feedback type="invalid">
                La fecha debe ser mayor o igual al día de hoy
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formBoardinghour">
              <Form.Label>Hora Check In</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="time"
                name="check_in_hour"
                onChange={handleInputChange}
                value={data.check_in_hour}
                isInvalid={dataSended && !validatedFiedls.check_in_hour}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formCheckOut">
              <Form.Label>Fecha Check Out</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                name="check_out"
                onChange={handleInputChange}
                value={data.check_out}
                isInvalid={dataSended && !validatedFiedls.check_out}
              />
              <Form.Control.Feedback type="invalid">
                La fecha debe ser mayor o igual al día de hoy
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formCheckOuthour">
              <Form.Label>Hora Check Out</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="time"
                name="check_out_hour"
                onChange={handleInputChange}
                value={data.check_out_hour}
                isInvalid={dataSended && !validatedFiedls.check_out_hour}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={12} lg={6} className="mb-4 mb-lg-2">
            <Form.Group controlId="formReservation">
              <Form.Label>Reserva</Form.Label>
              <Form.Control
                onChange={handleAddReservationFile}
                disabled={blockAllFields}
                type="file"
                required
                isInvalid={dataSended && !validatedFiedls.reservation}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4 mb-lg-2">
            <Form.Group controlId="formCost">
              <Form.Label>Costo</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  disabled={blockAllFields}
                  type="number"
                  onChange={handleInputChange}
                  value={data.cost}
                  name="cost"
                  isInvalid={dataSended && !validatedFiedls.cost}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} lg={{ offset: 2, span: 2 }} className="mt-4">
            <Button
              className="w-100"
              variant="warning"
              type="submit"
              disabled={blockAllFields}
            >
              {buttonName}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

/* eslint-enable camelcase */

const mapStateToProps = (state) => ({
  foodPlans: state.foodPlan.all,
  hostingState: state.hosting,
  agencyRuc: state.agency.ruc,
});

const actions = {
  createHostingAPI: createHostingAction,
  updateHostingAPI: updateHostingAction,
};

export default connect(mapStateToProps, actions)(HostingForm);
