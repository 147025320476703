import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet-async";
// eslint-disable-next-line import/no-unresolved
import { Analytics } from "@vercel/analytics/react";
// eslint-disable-next-line import/no-unresolved
import { SpeedInsights } from "@vercel/speed-insights/react";
import generateStore from "./redux/store";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/admin/AdminRoute";
import {
  VIEW_URL,
  HomePath,
  LoginPath,
  QandAPath,
  AdminPath,
  PackagePath,
  UserInfoPath,
  CalendarPath,
  AboutUsPath,
  ContactsPath,
  NewPassengerPath,
  NewJourneyPath,
  PassengersPath,
  ActivateUserPath,
  ResetPasswordPath,
  PrivacyPolicyPath,
  ReportProblemPath,
  UpdateUserDataPath,
  ChangePasswordPath,
  TermsAndConditionsPath,
  ImportantInformationPath,
  PassengerInformationPath,
  ResetPasswordConfirmPath,
} from "./utils/paths";
import ScrollToTop from "./components/ScrollToTop";
import Loading from "./pages/Loading";
import LogIn from "./pages/user/LogIn";
import Contacts from "./pages/public/Contacts";
import AboutUs from "./pages/public/AboutUs";
import QandA from "./pages/public/QandA";
import Home from "./pages/Home";
import PassengerInformation from "./pages/PassengerInformation";

function App() {
  const store = generateStore();

  // TODO ver como manejar que se oculte el navbar en los telefonos
  const PrivacyPolicy = lazy(() => import("./pages/public/PrivacyPolicy"));
  // const Contacts = lazy(() => import("./pages/public/Contacts"));
  // const AboutUs = lazy(() => import("./pages/public/AboutUs"));
  // const QandA = lazy(() => import("./pages/public/QandA"));
  const NotFound = lazy(() => import("./pages/NotFound"));
  // const Home = lazy(() => import("./pages/Home"));
  // const PassengerInformation = lazy(
  //   () => import("./pages/PassengerInformation")
  // );
  const ImportantInformation = lazy(
    () => import("./pages/ImportantInformation")
  );
  const TermsAndConditions = lazy(
    () => import("./pages/public/TermsAndConditions")
  );

  // admin
  const MyCalendar = lazy(() => import("./components/admin/MyCalendar"));
  const NewPassenger = lazy(() => import("./pages/admin/NewPassenger"));
  const Passengers = lazy(() => import("./pages/admin/Passengers"));
  const NewJourney = lazy(() => import("./pages/admin/NewJourney"));
  const Package = lazy(() => import("./pages/admin/Package"));
  const Admin = lazy(() => import("./pages/admin/Admin"));

  // user
  // const LogIn = lazy(() => import("./pages/user/LogIn"));
  const MyInfo = lazy(() => import("./pages/user/MyInfo"));
  const ActivateUser = lazy(() => import("./pages/user/ActivateUser"));
  const ResetPassword = lazy(() => import("./pages/user/ResetPassword"));
  const ReportProblem = lazy(() => import("./pages/user/ReportProblem"));
  const UpdateUserData = lazy(() => import("./pages/user/UpdateUserData"));
  const ChangePassword = lazy(() => import("./pages/user/ChangePassword"));
  const ResetPasswordConfirm = lazy(
    () => import("./pages/user/ResetPasswordConfirm")
  );

  return (
    <Provider store={store}>
      <Analytics />
      <SpeedInsights />
      <Helmet>
        <title>Epic Journey - Tu Agencia de viajes Tecnológica</title>
        <link rel="canonical" href={VIEW_URL} />
        <meta
          name="description"
          content="Descubre el mundo con nuestra agencia de viajes en Guayaquil, Ecuador. Tecnología avanzada y una plataforma exclusiva para nuestros clientes. ¡Viajar nunca fue tan fácil!"
        />
      </Helmet>

      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Layout>
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Navigate to={HomePath} />} />
              <Route exact path={HomePath} element={<Home />} />
              <Route exact path={ContactsPath} element={<Contacts />} />
              <Route exact path={LoginPath} element={<LogIn />} />
              <Route exact path={ActivateUserPath} element={<ActivateUser />} />
              <Route exact path={QandAPath} element={<QandA />} />
              <Route exact path={AboutUsPath} element={<AboutUs />} />
              <Route
                exact
                path={ResetPasswordPath}
                element={<ResetPassword />}
              />
              <Route
                exact
                path={PrivacyPolicyPath}
                element={<PrivacyPolicy />}
              />
              <Route
                exact
                path={TermsAndConditionsPath}
                element={<TermsAndConditions />}
              />

              <Route
                exact
                path={ResetPasswordConfirmPath}
                element={<ResetPasswordConfirm />}
              />

              {/* Private route */}
              <Route exact path="/" element={<AdminRoute />}>
                <Route exact path={NewJourneyPath} element={<NewJourney />} />
                <Route exact path={PassengersPath} element={<Passengers />} />
                <Route exact path={PackagePath} element={<Package />} />
                <Route exact path={CalendarPath} element={<MyCalendar />} />
                <Route exact path={AdminPath} element={<Admin />} />
                <Route
                  exact
                  path={NewPassengerPath}
                  element={<NewPassenger />}
                />
              </Route>

              <Route exact path="/" element={<PrivateRoute />}>
                <Route path={UserInfoPath} element={<MyInfo />} />
                <Route path={ChangePasswordPath} element={<ChangePassword />} />
                <Route path={UpdateUserDataPath} element={<UpdateUserData />} />
                <Route path={ReportProblemPath} element={<ReportProblem />} />
                <Route
                  path={ImportantInformationPath}
                  element={<ImportantInformation />}
                />
                <Route
                  path={PassengerInformationPath}
                  element={<PassengerInformation />}
                />
              </Route>

              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}

export default App;
