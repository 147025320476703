import React from "react";
import { Accordion, Container } from "react-bootstrap";
import Invoices from "./billing/Invoices";
import Payments from "./billing/Payments";

const Billing = ({ payments, invoices, showInvoicePassengerCol = false }) => {
  return (
    <Container>
      <Accordion>
        <Invoices
          invoices={invoices}
          showPassengerCol={showInvoicePassengerCol}
        />
        <Payments payments={payments} />
      </Accordion>
    </Container>
  );
};

export default Billing;
