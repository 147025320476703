import React, { Fragment, useState } from "react";
import { Navbar, Nav, Container, Button, NavDropdown } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { MdLogout } from "react-icons/md";
import AdminNav from "./admin/AdminNav";
import EpicIcon from "../assets/epic_icon.svg";
import { logoutAction } from "../redux/ducks/authDucks";
import {
  LoginPath,
  QandAPath,
  HomePath,
  AboutUsPath,
  ContactsPath,
  UserInfoPath,
  ReportProblemPath,
  ChangePasswordPath,
  PassengerInformationPath,
  ImportantInformationPath,
} from "../utils/paths";

function NavBar({ user, logOutAPI, isLoggedIn }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const handleLogIn = () => {
    setIsExpanded(false);
    navigate(LoginPath);
  };

  const handleLogOut = () => {
    logOutAPI();
    navigate(LoginPath);
  };

  const handleUpdateUserData = () => {
    setIsExpanded(false);
    navigate(UserInfoPath);
  };
  const handleReportProblem = () => {
    setIsExpanded(false);
    navigate(ReportProblemPath);
  };
  const handleImportantInformation = () => {
    setIsExpanded(false);
    navigate(ImportantInformationPath);
  };
  const handleChangePassword = () => {
    setIsExpanded(false);
    navigate(ChangePasswordPath);
  };

  return (
    <Navbar
      expanded={isExpanded}
      expand="md"
      bg="dark"
      variant="dark"
      sticky="top"
    >
      <Container>
        <Navbar.Brand href={HomePath}>
          <img
            alt=""
            src={EpicIcon}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          <strong>
            <span className="text-warning">EPIC</span>JOURNEY
          </strong>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setIsExpanded(!isExpanded)}
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="col-md-6 ms-md-auto pr-0 justify-content-end"
        >
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Link
              className="nav-link"
              to={HomePath}
              onClick={() => setIsExpanded(false)}
            >
              Home
            </Link>

            <Link
              className="nav-link"
              to={PassengerInformationPath}
              onClick={() => setIsExpanded(false)}
            >
              Consultar mi viaje
            </Link>

            {user.isAdmin ? (
              <AdminNav setIsExpanded={setIsExpanded} />
            ) : (
              <Fragment>
                <Link
                  className="nav-link"
                  to={ContactsPath}
                  onClick={() => setIsExpanded(false)}
                >
                  Contactos
                </Link>

                <Link
                  className="nav-link"
                  to={QandAPath}
                  onClick={() => setIsExpanded(false)}
                >
                  Preguntas Frecuentes
                </Link>

                <Link
                  className="nav-link"
                  to={AboutUsPath}
                  onClick={() => setIsExpanded(false)}
                >
                  Quienes Somos
                </Link>
              </Fragment>
            )}
          </Nav>

          {isLoggedIn ? (
            <NavDropdown
              title={`${user.first_name} ${user.last_name}`}
              className="text-light"
            >
              <NavDropdown.Item onClick={handleUpdateUserData}>
                Mis Datos Personales
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleChangePassword}>
                Cambiar Contraseña
              </NavDropdown.Item>
              {/* <NavDropdown.Item onClick={handleReportProblem}>
                Reportar Problema
              </NavDropdown.Item> */}
              <NavDropdown.Item onClick={handleImportantInformation}>
                Información Importante
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogOut}>
                Cerrar Session <MdLogout />
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Button variant="warning" onClick={handleLogIn}>
              Iniciar Sesion
            </Button>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isAuth,
});

const actions = {
  logOutAPI: logoutAction,
};

export default connect(mapStateToProps, actions)(NavBar);
