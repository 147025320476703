import React, { useState } from "react";
import { Container, Accordion, Col, Row, Button } from "react-bootstrap";
import HostingForm from "../../admin/newJourney/services/HostingForm";
import { openCoordsGoogleMaps } from "../../../utils/coordinates";
import { infoOverlay, pendingHeader, pendingInfo } from "./utils";
import ModalUpdateService from "../ModalUpdateService";
import { openPDFInTab } from "../../../utils/pdf";

/* eslint-disable camelcase */
const Hosting = ({ data, isAdmin }) => {
  if (!data) return null;
  const [create, setCreate] = useState(false); // create service in case it doesnt exist
  const [showModalUpdateService, setShowModalUpdateService] = useState(false);
  const { id, status, service, service_name } = data;

  const {
    cost,
    reservation,
    name,
    address,
    check_in,
    check_out,
    food_plan,
    location,
  } = service || {};

  const { latitude, longitude } = location || {};
  const checkIn = new Date(check_in);
  const checkOut = new Date(check_out);

  const handleCreateService = () => {
    setCreate(true);
    setShowModalUpdateService(true);
  };

  return (
    <Accordion.Item eventKey={id}>
      {showModalUpdateService && (
        <ModalUpdateService
          show={showModalUpdateService}
          setShow={setShowModalUpdateService}
          create
        >
          <HostingForm
            dataInput={create ? null : service}
            packageServiceID={id}
          />
        </ModalUpdateService>
      )}

      <Accordion.Header>
        {service_name.name} {status === "P" && pendingHeader()}
      </Accordion.Header>

      <Accordion.Body>
        {status === "P" && pendingInfo(isAdmin, handleCreateService)}

        {service && (
          <Container className="p-0">
            <Row>
              <Col lg={10}>
                <Container className="p-0">
                  <Row className="mb-1">
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Nombre:</strong> {name}
                    </Col>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Dirección:</strong> {address}
                    </Col>
                  </Row>

                  <Row className="mb-1">
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Plan Alimenticio:</strong> {food_plan.name}
                      {infoOverlay(food_plan.description)}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Check In:</strong> {checkIn.toLocaleString()}
                    </Col>
                    <Col xs={12} lg={6} className="mb-2">
                      <strong>Check Out:</strong> {checkOut.toLocaleString()}
                    </Col>
                  </Row>

                  {isAdmin && (
                    <Row>
                      <Col xs={12} lg={6} className="mb-1">
                        <strong>Costo:</strong> ${cost}
                      </Col>
                    </Row>
                  )}
                </Container>
              </Col>

              <Col>
                <Container>
                  <Row>
                    <Button
                      className="mb-2"
                      variant="warning"
                      onClick={() => openPDFInTab(reservation)}
                    >
                      Ver Ticket
                    </Button>

                    <Button
                      className="mb-2"
                      variant="warning"
                      onClick={() => openCoordsGoogleMaps(latitude, longitude)}
                    >
                      Ver Ubicación
                    </Button>

                    {isAdmin && (
                      <Button
                        variant="warning"
                        onClick={() => setShowModalUpdateService(true)}
                      >
                        Actualizar
                      </Button>
                    )}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

/* eslint-enable camelcase */

export default Hosting;
