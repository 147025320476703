import axios from "axios";
import { dispatchAction } from "../utils";
import {
  ALL_COUNTRIES_ENDPOINT,
  CITIES_BY_COUNTRY_ENDPOINT,
  PERSON_CHOICES_ENDPOINT,
} from "../endPoints";

const data = {
  countries: [],
  cities_by_country: [],
  start_point: { countryCode: null, cities: [] }, // useful for creating a package
  destination_point: { countryCode: null, cities: [] },
  choices: { genders: {}, civil_statuses: {} },
};

const GET_ALL_COUNTRIES_SUCCESS_SIGNAL = "GET_ALL_COUNTRIES_SUCCESS";
const GET_ALL_COUNTRIES_FAILED_SIGNAL = "GET_ALL_COUNTRIES_FAILED";

const GET_CITIES_BY_COUNTRY_SUCCESS_SIGNAL = "GET_CITIES_BY_COUNTRY_SUCCESS";
const GET_CITIES_BY_COUNTRY_FAILED_SIGNAL = "GET_CITIES_BY_COUNTRY_FAILED";

const CITIES_BY_START_COUNTRY_SUCCESS_SIGNAL =
  "CITIES_BY_START_COUNTRY_SUCCESS";
const CITIES_BY_DESTINATION_COUNTRY_SUCCESS_SIGNAL =
  "CITIES_BY_DESTINATION_COUNTRY_SUCCESS";

const GET_ALL_CHOICES_SUCCESS_SIGNAL = "GET_ALL_CHOICES_SUCCESS";
const GET_ALL_CHOICES_FAILED_SIGNAL = "GET_ALL_CHOICES_FAILED";

// eslint-disable-next-line default-param-last
export default function utilsReducer(state = data, action) {
  switch (action.type) {
    case GET_ALL_COUNTRIES_SUCCESS_SIGNAL:
      return { ...state, countries: action.payload };

    case GET_CITIES_BY_COUNTRY_SUCCESS_SIGNAL:
      return { ...state, cities_by_country: action.payload };

    case CITIES_BY_START_COUNTRY_SUCCESS_SIGNAL:
      return { ...state, start_point: action.payload };

    case CITIES_BY_DESTINATION_COUNTRY_SUCCESS_SIGNAL:
      return { ...state, destination_point: action.payload };

    case GET_ALL_CHOICES_SUCCESS_SIGNAL:
      return { ...state, choices: action.payload };

    default:
      return state;
  }
}

export const getAllCountriesAction = () => async (dispatch) => {
  try {
    const res = await axios.get(ALL_COUNTRIES_ENDPOINT);
    dispatchAction(dispatch, GET_ALL_COUNTRIES_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, GET_ALL_COUNTRIES_FAILED_SIGNAL);
  }
};

export const getCitiesByCountryAction =
  (countryCode, isStartPoint = false, isDestinationPoint = false) =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        `${CITIES_BY_COUNTRY_ENDPOINT}/${countryCode}`
      );

      let signal = GET_CITIES_BY_COUNTRY_SUCCESS_SIGNAL;
      let resData = res.data;
      if (isStartPoint) {
        signal = CITIES_BY_START_COUNTRY_SUCCESS_SIGNAL;
        resData = { countryCode, cities: resData };
      } else if (isDestinationPoint) {
        signal = CITIES_BY_DESTINATION_COUNTRY_SUCCESS_SIGNAL;
        resData = { countryCode, cities: resData };
      }
      dispatchAction(dispatch, signal, resData);
    } catch {
      dispatchAction(dispatch, GET_CITIES_BY_COUNTRY_FAILED_SIGNAL);
    }
  };

export const getAllChoicesAction = () => async (dispatch) => {
  try {
    const res = await axios.get(PERSON_CHOICES_ENDPOINT);
    dispatchAction(dispatch, GET_ALL_CHOICES_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, GET_ALL_CHOICES_FAILED_SIGNAL);
  }
};
