import React, { useState } from "react";
import { Container, Accordion, Col, Row, Button, Badge } from "react-bootstrap";
import AirTicketForm from "../../admin/newJourney/services/AirTicketForm";
import ModalUpdateService from "../ModalUpdateService";
import { pendingHeader, pendingInfo } from "./utils";
import { openPDFInTab } from "../../../utils/pdf";

/* eslint-disable camelcase */
const AirTicket = ({
  data,
  isAdmin,
  cityStart,
  cityDestination,
  serviceDirections,
  setServiceIDSelected,
  setShowTicketsByPassenger,
}) => {
  if (!data) return null;
  const [showModalUpdateService, setShowModalUpdateService] = useState(false);
  const [create, setCreate] = useState(false); // create service in case it doesnt exist
  const { id, status, service, service_name, travel_direction, order } = data;
  const {
    cost,
    arrival_time,
    boarding_time,
    has_stopovers,
    departure_airport,
    destination_airport,
    flight_number,
    luggage_types,
    reservation,
    reservation_code,
  } = service || {};

  const serviceDirection = serviceDirections[travel_direction];
  const boardingTime = new Date(boarding_time);
  const arrivalTime = new Date(arrival_time);
  const learnAboutLuggages = () => {
    const url = "https://www.avianca.com/es/informacion-y-ayuda/equipaje/";
    window.open(url, "_blank");
  };

  const handleClickSeePassengerTickets = () => {
    setServiceIDSelected(id);
    setShowTicketsByPassenger(true);
  };

  const handleCreateService = () => {
    setCreate(true);
    setShowModalUpdateService(true);
  };

  return (
    <Accordion.Item eventKey={id}>
      {showModalUpdateService && (
        <ModalUpdateService
          show={showModalUpdateService}
          setShow={setShowModalUpdateService}
          create
        >
          <AirTicketForm
            dataInput={create ? null : service}
            packageServiceID={id}
            cityStart={cityStart}
            cityDestination={cityDestination}
            travelDirection={travel_direction}
          />
        </ModalUpdateService>
      )}

      <Accordion.Header>
        {service_name.name}
        {departure_airport && `: ${departure_airport.name}`}
        <Badge bg="secondary" className="ms-2">
          {serviceDirection}
        </Badge>
        {status === "P" && pendingHeader()}
      </Accordion.Header>

      <Accordion.Body>
        {status === "P" && pendingInfo(isAdmin, handleCreateService)}

        {service && (
          <Container className="p-0">
            <Row>
              <Col lg={10}>
                <Container className="p-0">
                  <Row className="mb-1">
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Código de reserva:</strong> {reservation_code}
                      {has_stopovers && (
                        <Badge bg="secondary" className="ms-2">
                          Tiene Escalas
                        </Badge>
                      )}
                    </Col>

                    {flight_number && (
                      <Col xs={12} lg={6} className="mb-1">
                        <strong>Número de vuelo:</strong> {flight_number}
                      </Col>
                    )}
                  </Row>

                  <Row className="mb-1">
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Aeropuerto Salida:</strong>{" "}
                      {departure_airport.name}
                    </Col>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Aeropuerto Llegada:</strong>{" "}
                      {destination_airport.name}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Hora Salida:</strong>{" "}
                      {boardingTime.toLocaleString()}
                    </Col>
                    <Col xs={12} lg={6} className="mb-2">
                      <strong>Hora Llegada:</strong>{" "}
                      {arrivalTime.toLocaleString()}
                    </Col>
                  </Row>

                  <Row className="mb-1">
                    <Col xs={12} className="mb-1">
                      <strong>Tipo de Equipaje:</strong>{" "}
                      {luggage_types.map((e) => e.name).join(", ")}
                      <Button variant="link" onClick={learnAboutLuggages}>
                        Aprender sobre equipajes
                      </Button>
                    </Col>
                  </Row>

                  {isAdmin && (
                    <Row>
                      <Col xs={12} lg={6} className="mb-1">
                        <strong>Costo:</strong> ${cost}
                      </Col>
                    </Row>
                  )}
                </Container>
              </Col>

              <Col>
                <Container>
                  <Row>
                    <Button
                      className="mb-2"
                      variant="warning"
                      onClick={() => openPDFInTab(reservation)}
                    >
                      Reservación
                    </Button>

                    {/* 
                    <Button
                      className="mb-2"
                      variant="warning"
                      onClick={handleClickSeePassengerTickets}
                    >
                      Ver Tickets
                    </Button> */}

                    {isAdmin && (
                      <Button
                        variant="warning"
                        onClick={() => setShowModalUpdateService(true)}
                      >
                        Actualizar
                      </Button>
                    )}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

/* eslint-enable camelcase */

export default AirTicket;
