import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Image } from "react-bootstrap";
import { getImagesSections } from "../../firebase/config";
import { AboutUsPath, VIEW_URL } from "../../utils/paths";

const AboutUs = () => {
  const [aboutUsImg, setAboutUsImg] = useState("");

  useEffect(() => {
    async function fetchData() {
      const tmpImg = await getImagesSections();
      setAboutUsImg(tmpImg.aboutUs);
    }
    fetchData();
  }, []);

  return (
    <Container className="p-0" fluid>
      <h1 style={{ display: "none" }}>Quienes Somos - Epic Journey</h1>
      <Helmet>
        <title>Quienes Somos</title>
        <link rel="canonical" href={`${VIEW_URL}${AboutUsPath}`} />
        <meta
          name="description"
          content="Somos una agencia de viaje Ecuatoriana compremetidas con el uso de la técnologia para mejorar tu experiencia al viajar!"
        />
      </Helmet>

      <Image src={aboutUsImg} className="full-image" />
      <Container className="mt-5 mb-5">
        <h2 className="text-center main-text-color fw-bold">Epic Visión</h2>
        <h3 className="text-center gray-text-color">
          Ser la agencia de viajes líder en uso de tecnología
        </h3>

        <Container className="text-center mt-4">
          <p style={{ fontSize: "1.05rem" }}>
            Ofrecer servicios personalizados, eficientes y de calidad para
            convertir los sueños y deseos de nuestros clientes en realidad.
            Nuestra vocación es guiar a cada viajero para que explore el mundo
            de forma ágil, segura y cómoda junto con el uso de tecnologías,
            ofreciendo una experiencia de viaje épica e inolvidable.
          </p>
        </Container>
      </Container>
      <br />
      <br />
      <Container className="mt-5 mb-5">
        <h2 className="text-center main-text-color fw-bold">Epic Misión</h2>
        <h3 className="text-center gray-text-color">
          Revolucionar el proceso operativo turístico empleando tecnología
        </h3>

        <Container className="text-center mt-4">
          <p style={{ fontSize: "1.05rem" }}>
            Aspirar ser la agencia de viajes innovadora, reconocida por
            transformar los viajes en experiencias extraordinarias. Comprometida
            con el uso de tecnología y plataformas digitales para ofrecer un
            servicio insuperable en conjunto con el turismo responsable. Nuestro
            objetivo es crecer estratégicamente, desarrollando una red que nos
            permita ofertar un servicio excepcional en cada parte del mundo.
          </p>
        </Container>
      </Container>
    </Container>
  );
};

export default AboutUs;
