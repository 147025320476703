import React, { createContext, useState, useMemo } from "react";

const AppContext = createContext();

function AppContextProvider({ children }) {
  const [user, setUser] = useState({
    first_names: null,
    last_names: null,
    isAdmin: false,
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const contextProviderValue = useMemo(
    () => ({ isLoggedIn, setIsLoggedIn, isAdmin, user, setUser }),
    [isLoggedIn, isAdmin, user]
  );

  return (
    <AppContext.Provider value={contextProviderValue}>
      {children}
    </AppContext.Provider>
  );
}

export { AppContext, AppContextProvider };
