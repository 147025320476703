import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import ModalTermsAndCondicions from "../../components/admin/newPassenger/ModalTermsAndCondicions";
import { logInAction } from "../../redux/ducks/authDucks";
import {
  VIEW_URL,
  AdminPath,
  LoginPath,
  PassengerInformationPath,
  ResetPasswordPath,
} from "../../utils/paths";

const LogIn = ({ logInAPI, user, isAuthenticated }) => {
  const [ShowTermsAndCondicions, setShowTermsAndCondicions] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleShowTermsAndCondicionsModal = () =>
    setShowTermsAndCondicions(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    logInAPI(username, password);
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user.isAdmin) navigate(AdminPath);
      else navigate(PassengerInformationPath);
    }
  }, [user]);

  return (
    <Container className="mt-5">
      <Helmet>
        <title>Iniciar Session</title>
        <link rel="canonical" href={`${VIEW_URL}${LoginPath}`} />
        <meta
          name="description"
          content="Inicia session en nuestra plataforma web para mejorar tu experiencia al viajar!"
        />
      </Helmet>
      <ModalTermsAndCondicions
        show={ShowTermsAndCondicions}
        setShow={setShowTermsAndCondicions}
      />
      <Row className="justify-content-center">
        <Col md={5}>
          <h1 className="mb-4">Iniciar sesión</h1>
          <Form>
            <Form.Group controlId="form-Username" className="mb-4">
              <Form.Label>Cédula</Form.Label>
              <Form.Control
                type="username"
                placeholder="Ingresa su cédula"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="form-Password" className="mb-4">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Button
                variant="link"
                onClick={() => navigate(ResetPasswordPath)}
              >
                ¿Olvidaste tu contraseña?
              </Button>
            </Form.Group>

            <Button
              onClick={handleSubmit}
              variant="warning"
              type="submit"
              className="mb-4"
            >
              Iniciar sesión{" "}
              {user.isLoading ? (
                <Spinner animation="border" size="sm" role="status" />
              ) : null}
            </Button>
          </Form>
        </Col>
      </Row>

      <Row className="justify-content-md-center mt-5">
        <Col md="5">
          Al Iniciar sesión, estas aceptando nuestros{" "}
          <Button variant="link" onClick={handleShowTermsAndCondicionsModal}>
            Términos y Condiciones
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuth,
});

const actions = {
  logInAPI: logInAction,
};

export default connect(mapStateToProps, actions)(LogIn);
