import React from "react";
import { FcFlashOn } from "react-icons/fc";
import { TbTargetArrow } from "react-icons/tb";
import { BiWorld } from "react-icons/bi";
import { MdSupportAgent } from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { Container, Row, Card, Col } from "react-bootstrap";
import { MAIN_COLOR } from "../../utils/constants";

const Benefits = () => {
  const iconSize = 80;

  const benefitsData = [
    {
      icon: <FcFlashOn size={iconSize} color={MAIN_COLOR} />,
      title: "Rapidéz de respuesta",
      description:
        "Sabemos que el tiempo es importante para ti. Por eso te garantizamos una respuesta inmediata a tus requerimientos",
    },
    {
      icon: <TbTargetArrow size={iconSize} color={MAIN_COLOR} />,
      title: "Todo en un solo lugar",
      description:
        "Accedes a tus documentos de tus viajes desde nuestra plataforma. Podrás ver tus tickets aereos, reservaciones y mas!",
    },
    {
      icon: <BiWorld size={iconSize} color={MAIN_COLOR} />,
      title: "Disponilidad y acceso",
      description:
        "Puedes consultar tus documentos desde cualquier parte del mundo a cualquier hora del día",
    },
    {
      icon: <MdSupportAgent size={iconSize} color={MAIN_COLOR} />,
      title: "Asistencia 24/7",
      description:
        "Nuestros agentes te pueden brindar asistencia a cualquier hora por la eventualidad que se te presente",
    },
    {
      icon: <FaRegCalendarCheck size={iconSize} color={MAIN_COLOR} />,
      title: "Cotizaciones Remotas",
      description:
        "Si no puedes acudir a nuestra agencia, podemos agencar una reunión para brindarte el soporte y asistencias que desees.",
    },
  ];

  return (
    <Container fluid className="p-5 bg-light">
      <Container className="mt-5 mb-5 pt-5 pb-5">
        <h2 className="text-center main-text-color fw-bold">Beneficios</h2>
        <h3 className="text-center gray-text-color">Por qué elegirnos</h3>

        <Row className="mt-5 justify-content-center align-items-center  ">
          {benefitsData.map((benefit, index) => (
            <Col key={index} className="text-center">
              <Card
                className="border-0 mb-4"
                style={{ backgroundColor: "transparent" }}
              >
                <Card.Img as="div" variant="top">
                  {benefit.icon}
                </Card.Img>
                <Card.Body>
                  <Card.Title>{benefit.title}</Card.Title>
                  <Card.Text>{benefit.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default Benefits;
