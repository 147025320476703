import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IoLogoWhatsapp } from "react-icons/io";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { createWebQuoteAction } from "../../redux/ducks/agency/webQuoteDuck";
import { getAllCountriesAction } from "../../redux/ducks/utilsDucks";

const QuoteNow = ({
  getAllCountriesAPI,
  createWebQuoteAPI,
  countries,
  agencyRuc,
  quoteState,
}) => {
  const { isLoading, creationSuccessful } = quoteState;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    last_name: "",
    first_name: "",
    phone: "",
    email: "",
    n_adults: "",
    n_children: "",
    travel_date: "",
    country: "",
    agency: agencyRuc,
  });

  const onChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (countries.length === 0) {
      getAllCountriesAPI();
    }
  }, [countries]);

  const sendData = (event) => {
    event.preventDefault();
    event.stopPropagation();

    createWebQuoteAPI(data);
  };

  return (
    <Container className="mb-5">
      <h2 className="text-center main-text-color fw-bold">Cotiza ahora</h2>
      <h3 className="text-center gray-text-color">
        Confía en nuestros agentes para planificar tus próximas vacaciones
      </h3>

      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col lg={8}>
            <p className="m-0">
              ¡Confía en nosotros para planificar tu escape perfecto!
            </p>
            <p>
              Completa este formulario y uno de nuestros asesores te contactará
              en
              <strong> menos de 24 horas!</strong>
            </p>

            <Form noValidate validated={validated} onSubmit={sendData}>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formFirstName">
                    <Form.Control
                      type="text"
                      placeholder="Nombres"
                      name="first_name"
                      onChange={onChange}
                      disabled={creationSuccessful}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formLastName">
                    <Form.Control
                      type="text"
                      placeholder="Apellidos"
                      name="last_name"
                      onChange={onChange}
                      disabled={creationSuccessful}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formPhone">
                    <Form.Control
                      type="text"
                      placeholder="Teléfono"
                      name="phone"
                      onChange={onChange}
                      disabled={creationSuccessful}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={onChange}
                      disabled={creationSuccessful}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formAdults">
                    <Form.Control
                      type="number"
                      placeholder="¿Cuántos adultos?"
                      name="n_adults"
                      onChange={onChange}
                      disabled={creationSuccessful}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formChildren">
                    <Form.Control
                      type="number"
                      placeholder="¿Cuántos niños?"
                      name="n_children"
                      onChange={onChange}
                      disabled={creationSuccessful}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formCountry">
                    <Form.Control
                      as="select"
                      name="country"
                      onChange={onChange}
                      disabled={creationSuccessful}
                    >
                      <option>Elige a donde quieres ir!</option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formTravelDate">
                    <Form.Control
                      type="date"
                      placeholder="Fecha de tentativa de viaje"
                      name="travel_date"
                      onChange={onChange}
                      disabled={creationSuccessful}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <p className="mt-3">
                O Comunícate directo con nosotros: +593 96 377 3494{" "}
                <IoLogoWhatsapp size={25} />
              </p>
              <Form.Group controlId="formPrivacy">
                <Form.Check
                  type="checkbox"
                  label="Acepto Políticas de Privadidad. Tu privacidad es importante para nosotros. Somos cuidadosos y respetuosos con tus datos. Para más información, por favor lee nuestras políticas de privacidad."
                  disabled={creationSuccessful}
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
              </Form.Group>

              <Row>
                <Button
                  variant="warning"
                  type="submit"
                  className="mt-3 fw-bold"
                  disabled={creationSuccessful || !termsAccepted}
                >
                  {creationSuccessful ? "Enviado!" : "Obtener la mejor oferta"}
                  {isLoading && <Spinner size="sm" />}
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  countries: state.utils.countries,
  agencyRuc: state.agency.ruc,
  quoteState: state.webQuote,
});

const actions = {
  getAllCountriesAPI: getAllCountriesAction,
  createWebQuoteAPI: createWebQuoteAction,
};

export default connect(mapStateToProps, actions)(QuoteNow);
