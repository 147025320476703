import axios from "axios";
import { toast } from "react-toastify";
import { updateToCompletePackageServiceAction } from "../journey/packageServiceDuck";
import { dispatchAction, getUserHeader, getFormData } from "../../utils";
import { TRAVEL_INSURANCE_ENDPOINT } from "../../endPoints";

const data = {
  created: null,
  updated: null,
};

const CREATE_INSURANCE_SUCCESS_SIGNAL = "CREATE_INSURANCE_SUCCESS";
const CREATE_INSURANCE_FAILED_SIGNAL = "CREATE_INSURANCE_FAILED";
const RESET_INSURANCE_STATE_SIGNAL = "RESET_INSURANCE_STATE_SIGNAL";

const UPDATE_INSURANCE_SUCCESS_SIGNAL = "UPDATE_INSURANCE_SUCCESS";
const UPDATE_INSURANCE_FAILED_SIGNAL = "UPDATE_INSURANCE_FAILED";

// eslint-disable-next-line default-param-last
export default function travelInsuranceReducer(state = data, action) {
  switch (action.type) {
    case CREATE_INSURANCE_SUCCESS_SIGNAL:
      return { ...state, created: action.payload };

    case UPDATE_INSURANCE_SUCCESS_SIGNAL:
      return { ...state, updated: action.payload };

    case RESET_INSURANCE_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetTravelInsuranceStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_INSURANCE_STATE_SIGNAL);
};

export const createTravelInsuranceAction =
  (payload, packageServiceID) => async (dispatch) => {
    try {
      const formData = getFormData(payload);
      const config = getUserHeader({ addFormData: true });
      const res = await axios.post(TRAVEL_INSURANCE_ENDPOINT, formData, config);
      if (res.status === 201) {
        toast.success("Seguro creado");
        dispatchAction(dispatch, CREATE_INSURANCE_SUCCESS_SIGNAL, res.data);
        if (packageServiceID)
          dispatch(
            updateToCompletePackageServiceAction(packageServiceID, res.data.id)
          );
      }
    } catch (error) {
      dispatchAction(dispatch, CREATE_INSURANCE_FAILED_SIGNAL);
      toast.error("Error al crear el Seguro");
    }
  };

export const updateTravelInsuranceAction = (payload) => async (dispatch) => {
  try {
    const formData = getFormData(payload);
    const endpoint = `${TRAVEL_INSURANCE_ENDPOINT}${payload.id}/`;
    const config = getUserHeader({ addFormData: true });
    const res = await axios.patch(endpoint, formData, config);
    if (res.status === 200) {
      toast.success("Seguro Actualizado");
      dispatchAction(dispatch, UPDATE_INSURANCE_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, UPDATE_INSURANCE_FAILED_SIGNAL);
    toast.error("Error al actualizar el Seguro");
  }
};
