import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { getCoverImages } from "../../firebase/config";

function ImagesSection() {
  const [coverImages, setCoverImages] = useState([]);
  const INTERVAL = 5000;

  useEffect(() => {
    async function fetchData() {
      const tmpImages = await getCoverImages();
      setCoverImages(tmpImages.imagesURLs);
    }
    fetchData();
  }, []);

  return (
    <Carousel data-bs-theme="dark">
      {coverImages.map((url, index) => (
        <Carousel.Item key={index} interval={INTERVAL}>
          <img className="d-block w-100" src={url} alt={`Portada ${index}`} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ImagesSection;
