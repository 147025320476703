import React from "react";
import { Container } from "react-bootstrap";

const TermsAndConditions = ({ showTitle = true }) => {
  const style = { textAlign: "justify" };

  return (
    <Container>
      {showTitle && <h2 className="mt-5">Términos y Condiciones</h2>}
      <p style={style} className="mb-5">
        Esta página web es propiedad y está operado por{" "}
        <strong>Epic Journey S.A.S.</strong> A continuación se establecen los
        términos y condiciones bajo los cuales puedes usar nuestra página web y
        nuestros servicios. Esta página web ofrece a los visitantes verificar
        sus reservaciones y vouchers de servicios y a su vez recordatorios
        cercanos a su fecha de viaje. Al acceder o usar la página web, aceptas
        haber leído, entendido y aceptado estar sujeto a estos Términos:
      </p>

      <h5 className="mt-5">Aceptación de las condiciones de uso</h5>
      <p style={style} className="mb-5">
        Estas Condiciones de uso rigen el uso del contenido y las funciones
        disponibles a través de los nombres de dominio www.epicjourneyagency.com
        (el Sitio Web de <strong>Epic Journey S.A.S</strong> o el Sitio Web)
        operado por OPERADOR (“<strong>Epic Journey S.A.S</strong>”). Al
        utilizar, navegar o visitar el sitio web, aceptas quedar libre y
        voluntariamente en virtud de estas Condiciones de Uso. Si no estás de
        acuerdo con estas Condiciones de Uso, no puedes usar nuesto sitio web.
        Estas Condiciones de Uso constituyen un contrato continuo entre tú y{" "}
        <strong>Epic Journey S.A.S</strong> y se aplican al uso que hagas del
        Sitio Web de <strong>Epic Journey S.A.S</strong>. Estas Condiciones de
        Uso debes leerlas con atención. Los términos y condiciones de uso bajo
        los cuales la compañía ha realizado el presente acuerdo están sujetas a
        la Ley Orgánica de Protección de Datos Personales, y por lo tanto no son
        contrarías a derecho.
      </p>

      <h5 className="mt-5">Actualización de los Términos y Condiciones</h5>
      <p style={style} className="mb-5">
        <strong>Epic Journey S.A.S</strong> se reserva el derecho de modificar
        estas Condiciones de Uso en forma oportuna, con o sin aviso previo, a su
        entera y absoluta discreción. Se podrá consultar la versión más reciente
        de las Condiciones de Uso haciendo clic en “Términos y Condiciones”, que
        se encuentra en la parte inferior de las páginas del Sitio Web. La
        versión más reciente de los Términos y condiciones reemplaza todas las
        versiones anteriores. El uso que hagas del Sitio Web de Epic Journey una
        vez efectuadas las modificaciones implica que aceptas quedar obligado
        por dichas modificaciones.
      </p>

      <h5 className="mt-5">Privacidad e Información Personal</h5>
      <p style={style} className="mb-5">
        Epic Journey ha asumido el compromiso de proteger la privacidad de la
        información personal que proporcionas en nuestro Sitio Web. Toda
        información enviada en el Sitio Web está sujeta a la Política de
        Privacidad, cuyos términos se incorporan al presente. Debes consultar la
        Política de Privacidad para comprender nuestras prácticas. La fecha de
        toda modificación a nuestra Política de Privacidad aparecerá en la parte
        inferior de nuestra Política de Privacidad. Puedes consultar la Política
        de Privacidad haciendo clic en “Políticas de Privacidad”, que se
        encuentra en la parte inferior de las páginas del Sitio Web.
      </p>

      <h5 className="mt-5">TU USUARIO</h5>
      <p style={style} className="mb-5">
        Si aceptas el registro de tu usuaruio en el Sitio Web, asumes la
        responsabilidad de mantener la confidencialidad de tu cuenta y
        contraseña, y de restringir el acceso a tu cuenta, y te comprometes a
        aceptar la responsabilidad respecto de todas las actividades que se
        realicen en tu cuenta o con tu contraseña. El Sitio Web detalla los
        servicios turísticios contratados por adultos, que han efectuado su
        compra mediante los diferentes canales de compra/venta. Si tienes menos
        de 13, puedes navegar en el Sitio Web, pero no puedes registrar una
        cuenta. Epic Journey se reserva el derecho de negar el servicio,
        cancelar una cuenta, o eliminar o editar contenido.
      </p>

      <h5 className="mt-5">Tu Aceptación</h5>
      <p style={style} className="mb-5">
        Al utilizar y/o visitar el sitio web, manifiestas que aceptas estos
        terminos y condiciones (las “Condiciones del Servicio”) Y los términos y
        condiciones de la notificación de privacidad de
        <strong> Epic Journey S.A.S</strong>, que se incoporan al presente por
        su mención. Si no estás de acuerdo con alguna de estas condiciones, no
        utilices el Sitio Web de Epic Journey.
      </p>

      <h5 className="mt-5">Sitio Web de Epic Journey</h5>
      <p style={style} className="mb-5">
        Estas Condiciones de Servicio se aplican a todos los usuarios del Sitio
        Web, incluidos aquellos que también contribuyen con contenido en video,
        información, y todo otro material o servicio en el Sitio Web. El Sitio
        Web puede contener enlaces a sitios web de terceros, que no son de
        propiedad de Epic Journey ni están bajo su control. Epic Journey no
        controla el contenido, las políticas de privacidad ni las prácticas de
        ningún sitio web de terceros, ni asume responsabilidad alguna por estos.
        Asimismo, Epic Journey no monitoreará ni editará el contenido de ningún
        sitio web de terceros, ni puede hacerlo. Al utilizar el Sitio Web,
        liberas expresamente a Epic Journey de toda responsabilidad que surja
        del uso que hagas de algún sitio web de terceros. Por lo tanto, te
        recomendamos que prestes atención cuando abandonas el Sitio Web y que
        leas los términos y condiciones y la política de privacidad de todo otro
        sitio web que visites.
      </p>

      <h5 className="mt-5">Acceso Al Sitio Web</h5>
      <p style={style} className="mb-5">
        Epic Journey, por el presente, te otorga permiso para usar el Sitio Web
        según se establece en estas Condiciones de Servicio, siempre y cuando:
        (i) utilices el Sitio Web según lo autorizado, para su uso personal y no
        comercial; (ii) no copies ni distribuyas ninguna parte del Sitio Web en
        ningún medio, sin la previa autorización por escrito de Epic Journey;
        (iii) no alteres ni modifiques ninguna parte del Sitio Web, salvo según
        resulte razonablemente necesario para utilizar el Sitio Web para la
        finalidad que se le pretendió dar; y (iv) cumplas con los términos y
        condiciones de estas Condiciones de Uso. B. Para acceder a algunas
        funciones del Sitio Web, deberás aceptar la creación de tu cuenta. No
        debes utilizar la cuenta de ninguna otra persona sin permiso bajo
        ninguna circunstancia. Al crear tu cuenta, debes brindar información
        precisa y completa. Tú te haces exclusivamente responsable de la
        actividad que se realiza en tu cuenta, y debes conservar la contraseña
        de tu cuenta en forma segura. Deberás notificar inmediatamente a Epic
        Journey toda violación de la seguridad o uso no autorizado de tu cuenta.
        Si bien Epic Journey no asumirá responsabilidad alguna por las pérdidas
        ocasionadas por el uso no autorizado de tu cuenta, es posible que seas
        responsable de las pérdidas ocasionadas a Epic Journey u otras personas
        debido dicho uso no autorizado. C. Tú aceptas no utilizar ni lanzar
        ningún sistema automático, como por ejemplo, “robots”, “spiders”,
        “offline readers” (lectores sin conexión), etc. que accedan al Sitio Web
        de manera de enviar más mensajes de solicitud a los servidores de Epic
        Jounery en un período determinado de lo que una persona podría producir
        razonablemente en el mismo período mediante el uso convencional del
        navegador web en línea. Aceptas no recopilar ni almacenar ninguna
        información de identificación personal, incluidos nombres de cuenta, del
        Sitio Web, ni utilizar los sistemas de comunicación proporcionados por
        el Sitio Web para ofrecimientos comerciales. Aceptas no realizar a
        ningún usuario del Sitio Web ofrecimientos con fines comerciales con
        respecto a la Información Enviada como Usuario.
      </p>

      <h5 className="mt-5">Limitación De Responsabilidad</h5>
      <p style={style} className="mb-5">
        Sin perjuicio de los daños en los que pueda incurrir, la responsabilidad
        total de EPIC JOURNEY y cualquiera de sus proveedores en virtud de
        cualquier disposición de este Acuerdo y su recurso exclusivo por todo lo
        anterior se limitará al monto realmente pagado por usted para la
        plataforma. En la máxima medida permitida por la ley aplicable, en
        ningún caso EPIC JOURNEY o sus proveedores serán responsables de ningún
        daño especial, incidental, indirecto o consecuente de ningún tipo
        (incluidos, entre otros, daños por lucro cesante, por pérdida de datos u
        otra información, por interrupción del negocio, por lesiones personales,
        por la pérdida de privacidad que surja de alguna manera relacionada con
        el uso o la imposibilidad de usar la plataforma, software de terceros y
        / o - hardware de terceros utilizado con la plataforma, o de otro modo
        en relación con cualquier disposición de este Acuerdo), incluso si EPIC
        JOURNEY o cualquier proveedor ha sido informado de la posibilidad de
        tales daños e incluso si el recurso no cumple con su propósito esencial.
        Algunos estados / jurisdicciones no permiten la exclusión o limitación
        de daños incidentales o consecuentes, por lo que es posible que la
        limitación o exclusión anterior no se aplique en su caso.
      </p>

      <h5 className="mt-5">Indemnización</h5>
      <p style={style} className="mb-5">
        Usted acepta indemnizar y eximir de responsabilidad a EPIC JOURNEY y a
        sus empresas matrices, subsidiarias, afiliadas, funcionarios, empleados,
        agentes, socios y otorgantes de licencias (si corresponde) de cualquier
        reclamo o demanda, incluidos los honorarios razonables de abogados,
        debido a que surja de su: (a) uso de la plataforma; (b) violación de
        este Acuerdo o cualquier ley o reglamento; o (c) violación de cualquier
        derecho de un tercero.
      </p>
    </Container>
  );
};

export default TermsAndConditions;
