import axios from "axios";
import { dispatchAction, getUserHeader } from "../../utils";
import {
  AIRPORTS_BY_COUNTRY_ENDPOINT,
  AIRPORTS_BY_CITY_ENDPOINT,
} from "../../endPoints";

const data = {
  airportByCountryStart: [],
  airportByCountryDestination: [],
};

const RESET_AIRPORTS_STATE_SIGNAL = "RESET_AIRPORTS_STATE_SIGNAL";
const GET_AIRPORTS_BY_COUNTRY_SUCCESS_SIGNAL =
  "GET_AIRPORTS_BY_COUNTRY_SUCCESS";
const GET_AIRPORTS_BY_COUNTRY_FAILED_SIGNAL = "GET_AIRPORTS_BY_COUNTRY_FAILED";

const GET_AIRPORTS_BY_CITY_SUCCESS_SIGNAL = "GET_AIRPORTS_BY_CITY_SUCCESS";
const GET_AIRPORTS_BY_CITY_FAILED_SIGNAL = "GET_AIRPORTS_BY_CITY_FAILED";

// eslint-disable-next-line default-param-last
export default function aiportReducer(state = data, action) {
  switch (action.type) {
    case GET_AIRPORTS_BY_COUNTRY_SUCCESS_SIGNAL:
    case GET_AIRPORTS_BY_CITY_SUCCESS_SIGNAL:
      return { ...state, ...action.payload };

    case RESET_AIRPORTS_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetAirlineStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_AIRPORTS_STATE_SIGNAL);
};

export const getJourneyAirpotsByCountryAction =
  (countryStartCode, countryDestinationCode) => async (dispatch) => {
    try {
      const config = getUserHeader();
      const startEndpoint = `${AIRPORTS_BY_COUNTRY_ENDPOINT}/${countryStartCode}`;
      const destinationEndpoint = `${AIRPORTS_BY_COUNTRY_ENDPOINT}/${countryDestinationCode}`;
      const resStart = await axios.get(startEndpoint, config);
      const resDestination = await axios.get(destinationEndpoint, config);

      const tmp = {
        airportByCountryStart: resStart.data,
        airportByCountryDestination: resDestination.data,
      };

      dispatchAction(dispatch, GET_AIRPORTS_BY_COUNTRY_SUCCESS_SIGNAL, tmp);
    } catch (error) {
      dispatchAction(dispatch, GET_AIRPORTS_BY_COUNTRY_FAILED_SIGNAL);
      console.error(error);
    }
  };

export const getJourneyAirpotsByCityAction =
  (cityStartCode, cityDestinationCode) => async (dispatch) => {
    try {
      const config = getUserHeader();
      const startEndpoint = `${AIRPORTS_BY_CITY_ENDPOINT}/${cityStartCode}`;
      const destinationEndpoint = `${AIRPORTS_BY_CITY_ENDPOINT}/${cityDestinationCode}`;
      const resStart = await axios.get(startEndpoint, config);
      const resDestination = await axios.get(destinationEndpoint, config);

      const tmp = {
        airportByCountryStart: resStart.data,
        airportByCountryDestination: resDestination.data,
      };

      dispatchAction(dispatch, GET_AIRPORTS_BY_CITY_SUCCESS_SIGNAL, tmp);
    } catch (error) {
      dispatchAction(dispatch, GET_AIRPORTS_BY_CITY_FAILED_SIGNAL);
      console.error(error);
    }
  };
