import axios from "axios";
import { toast } from "react-toastify";
import { updateToCompletePackageServiceAction } from "../journey/packageServiceDuck";
import { dispatchAction, getUserHeader, getFormData } from "../../utils";
import { AIR_TICKET_ENDPOINT } from "../../endPoints";

const data = {
  created: null,
  updated: null,
};

const CREATE_AIR_TICKET_SUCCESS_SIGNAL = "CREATE_AIR_TICKET_SUCCESS";
const CREATE_AIR_TICKET_FAILED_SIGNAL = "CREATE_AIR_TICKET_FAILED";
const RESET_AIR_TICKET_STATE_SIGNAL = "RESET_AIR_TICKET_STATE_SIGNAL";

const UPDATE_AIR_TICKET_SUCCESS_SIGNAL = "UPDATE_AIR_TICKET_SUCCESS";
const UPDATE_AIR_TICKET_FAILED_SIGNAL = "UPDATE_AIR_TICKET_FAILED";

// eslint-disable-next-line default-param-last
export default function airTicketReducer(state = data, action) {
  switch (action.type) {
    case CREATE_AIR_TICKET_SUCCESS_SIGNAL:
      return { ...state, created: action.payload };

    case UPDATE_AIR_TICKET_SUCCESS_SIGNAL:
      return { ...state, updated: action.payload };

    case RESET_AIR_TICKET_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetAirTicketStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_AIR_TICKET_STATE_SIGNAL);
};

export const createAirTicketAction =
  (payload, packageServiceID) => async (dispatch) => {
    try {
      const formData = getFormData(payload);
      const config = getUserHeader({ addFormData: true });
      const res = await axios.post(AIR_TICKET_ENDPOINT, formData, config);
      if (res.status === 201) {
        toast.success("Boleto Aereo creado");
        dispatchAction(dispatch, CREATE_AIR_TICKET_SUCCESS_SIGNAL, res.data);
        if (packageServiceID)
          dispatch(
            updateToCompletePackageServiceAction(packageServiceID, res.data.id)
          );
      }
    } catch (error) {
      dispatchAction(dispatch, CREATE_AIR_TICKET_FAILED_SIGNAL);
      toast.error("Error al crear el Boleto Aereo");
    }
  };

export const updateAirTicketAction = (payload) => async (dispatch) => {
  try {
    const formData = getFormData(payload);
    const endpoint = `${AIR_TICKET_ENDPOINT}${payload.id}/`;
    const config = getUserHeader({ addFormData: true });
    const res = await axios.patch(endpoint, formData, config);
    if (res.status === 200) {
      toast.success("Boleto Aereo Actualizado");
      dispatchAction(dispatch, UPDATE_AIR_TICKET_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, UPDATE_AIR_TICKET_FAILED_SIGNAL);
    toast.error("Error al actualizar el Boleto Aereo");
  }
};
