import axios from "axios";
import { dispatchAction, getUserHeader } from "../../utils";
import { AGENCY_SERVICES_ENDPOINT } from "../../endPoints";

const data = {
  servicesByAgency: [],
};

const GET_AGENCY_SERVICES_SUCCESS_SIGNAL = "GET_AGENCY_SERVICES_SUCCESS";
const GET_AGENCY_SERVICES_FAILED_SIGNAL = "GET_AGENCY_SERVICES_FAILED";

// eslint-disable-next-line default-param-last
export default function agencyServicesReducer(state = data, action) {
  switch (action.type) {
    case GET_AGENCY_SERVICES_SUCCESS_SIGNAL:
      return { ...state, servicesByAgency: action.payload };

    default:
      return state;
  }
}

export const getServicesByAgencyAction = (agencyRUC) => async (dispatch) => {
  try {
    const config = getUserHeader();
    const res = await axios.get(
      `${AGENCY_SERVICES_ENDPOINT}/${agencyRUC}`,
      config
    );
    dispatchAction(dispatch, GET_AGENCY_SERVICES_SUCCESS_SIGNAL, res.data);
  } catch (error) {
    dispatchAction(dispatch, GET_AGENCY_SERVICES_FAILED_SIGNAL);
    console.error(error);
  }
};
