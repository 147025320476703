import axios from "axios";
import { toast } from "react-toastify";
import { getUserHeader } from "../../utils";
import { COORDINATE_ENDPOINT } from "../../endPoints";

export const createLocationAction = async (name, data) => {
  try {
    const payload = {
      name,
      latitude: data.split(",")[0],
      longitude: data.split(",")[1],
    };
    const config = getUserHeader();
    const res = await axios.post(COORDINATE_ENDPOINT, payload, config);
    if (res.status === 201) return res;
  } catch (error) {
    toast.error("Error al crear la Ubicación");
  }
  return null;
};

export const updateLocationAction = async (id, name, data) => {
  try {
    const payload = {
      name,
      latitude: data.split(",")[0],
      longitude: data.split(",")[1],
    };
    const config = getUserHeader();
    const endpoint = `${COORDINATE_ENDPOINT}${id}/`;
    const res = await axios.patch(endpoint, payload, config);
    if (res.status === 200) return res;
  } catch (error) {
    toast.error("Error al Actualiar la Ubicación");
  }
  return null;
};
