import axios from "axios";
import { dispatchAction, getUserHeader } from "../../utils";
import {
  DESINGED_PACKAGE_ENDPOINT,
  SERVICES_BY_DESINGED_PACKAGE_ENDPOINT,
} from "../../endPoints";

const data = {
  availables: [], // designed packeges disponibles
  servicesByDesignedPackage: [],
};

const RESET_DESIGNED_PACKAGES_STATE_SIGNAL = "RESET_DESIGNED_PACKAGES_STATE";
const GET_DESIGNED_PACKAGES_SUCCESS_SIGNAL = "GET_DESIGNED_PACKAGES_SUCCESS";
const GET_DESIGNED_PACKAGES_FAILED_SIGNAL = "GET_DESIGNED_PACKAGES_FAILED";

const RESET_SERVICES_BY_DESIGNED_PACKAGE_SIGNAL =
  "RESET_SERVICES_BY_DESIGNED_PACKAGE";
const SERVICES_BY_DESIGNED_PACKAGE_SUCCESS_SIGNAL =
  "SERVICES_BY_DESIGNED_PACKAGE_SUCCESS";
const SERVICES_BY_DESIGNED_PACKAGE_FAILED_SIGNAL =
  "SERVICES_BY_DESIGNED_PACKAGE_FAILED";

// eslint-disable-next-line default-param-last
export default function designedPackageReducer(state = data, action) {
  switch (action.type) {
    case GET_DESIGNED_PACKAGES_SUCCESS_SIGNAL:
      return { ...state, availables: action.payload };

    case SERVICES_BY_DESIGNED_PACKAGE_SUCCESS_SIGNAL:
      return { ...state, servicesByDesignedPackage: action.payload };

    case RESET_SERVICES_BY_DESIGNED_PACKAGE_SIGNAL:
      return { ...state, servicesByDesignedPackage: [] };

    case RESET_DESIGNED_PACKAGES_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const getDesignedPackagesAction = (agencyRUC) => async (dispatch) => {
  try {
    const config = getUserHeader();
    const params = { available: true };
    config.params = params;
    const res = await axios.get(
      `${DESINGED_PACKAGE_ENDPOINT}/${agencyRUC}`,
      config
    );
    dispatchAction(dispatch, GET_DESIGNED_PACKAGES_SUCCESS_SIGNAL, res.data);
  } catch (error) {
    dispatchAction(dispatch, GET_DESIGNED_PACKAGES_FAILED_SIGNAL);
    console.error(error);
  }
};

export const getServicesByDesignedPackageAction =
  (desigedID) => async (dispatch) => {
    try {
      const config = getUserHeader();
      const res = await axios.get(
        `${SERVICES_BY_DESINGED_PACKAGE_ENDPOINT}/${desigedID}`,
        config
      );
      const da = res.data;
      dispatchAction(dispatch, SERVICES_BY_DESIGNED_PACKAGE_SUCCESS_SIGNAL, da);
    } catch (error) {
      dispatchAction(dispatch, SERVICES_BY_DESIGNED_PACKAGE_FAILED_SIGNAL);
      console.error(error);
    }
  };

export const resetServicesByDesignedPackageAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_SERVICES_BY_DESIGNED_PACKAGE_SIGNAL);
};

export const resetDesignedPackageStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_DESIGNED_PACKAGES_STATE_SIGNAL);
};
