import React from "react";

export const requiredField = <span style={{ color: "red" }}>*</span>;

export const validateNumbersInPassword = (password) => {
  const regexNumber = /\d/;
  return regexNumber.test(password);
};

export const validateUpperAndLowerCaseInPassword = (password) => {
  const regexLowerCase = /[a-z]/;
  const regexUpperCase = /[A-Z]/;

  const hasLowerCase = regexLowerCase.test(password);
  const hasUpperCase = regexUpperCase.test(password);
  return hasLowerCase && hasUpperCase;
};

export const validateLenghtInPassword = (password) => {
  return password.length >= 8;
};

export function validateName(name) {
  const regexName = /^[A-Za-z\s]{1,}$/;
  return regexName.test(name);
}

export function validatePhone(number) {
  return /^\d{10}$/.test(number);
}

export function validateIDNumber(number) {
  // Verifica si la cadena es numérica y tiene una longitud de 10 caracteres
  return /^\d{10}$/.test(number);
}

export function validateEmail(email) {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexEmail.test(email);
}

export const validateDateGTToday = (selectedDate) => {
  // Validar si la fecha es mayor o igual a hoy
  const today = new Date();
  const selected = new Date(selectedDate);
  const offset = 60 * 60 * 24 * 1000;
  return selected.setHours(0, 0, 0, 0) + offset >= today.setHours(0, 0, 0, 0);
};

export function validateLocationStr(str) {
  // Expresión regular para validar el formato "float,float" o "float, float"
  const regex = /^\d+(\.\d+)?\s*,\s*\d+(\.\d+)?$/;
  return regex.test(str);
}
