import React from "react";
import { Modal } from "react-bootstrap";

function ModalUpdateService({ show, setShow, children, create = false }) {
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{create ? "Crear Servicio" : "Actualizar"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default ModalUpdateService;
