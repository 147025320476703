import React, { useState } from "react";
import { Container, Accordion, Col, Row, Button } from "react-bootstrap";
import { openPDFInTab } from "../../../utils/pdf";
import { pendingHeader, pendingInfo } from "./utils";

/* eslint-disable camelcase */
const Visa = ({ data, isAdmin }) => {
  if (!data) return null;
  const [create, setCreate] = useState(false); // create service in case it doesnt exist
  const { id, status, service, service_name } = data;
  // console.log(data);
  // console.log(Object.keys(service));

  const handleCreateService = () => {
    setCreate(true);
  };

  return (
    <Accordion.Item eventKey={id}>
      <Accordion.Header>
        {service_name.name} {status === "P" && pendingHeader()}
      </Accordion.Header>

      <Accordion.Body>
        {status === "P" && pendingInfo(isAdmin, handleCreateService)}
      </Accordion.Body>
    </Accordion.Item>
  );
};

/* eslint-enable camelcase */

export default Visa;
