import axios from "axios";
import { dispatchAction, getUserHeader } from "../../utils";
import { FOOD_PLAN_ENDPOINT } from "../../endPoints";

const data = {
  all: [],
};

const GET_ALL_FOOD_PLANS_SUCCESS_SIGNAL = "GET_ALL_FOOD_PLANS_SUCCESS";
const GET_ALL_FOOD_PLANS_FAILED_SIGNAL = "GET_ALL_FOOD_PLANS_FAILED";
const RESET_FOOD_PLAN_STATE_SIGNAL = "RESET_FOOD_PLAN_STATE_SIGNAL";

// eslint-disable-next-line default-param-last
export default function foodPlanReducer(state = data, action) {
  switch (action.type) {
    case GET_ALL_FOOD_PLANS_SUCCESS_SIGNAL:
      return { ...state, all: action.payload };

    case RESET_FOOD_PLAN_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetFoodPlanStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_FOOD_PLAN_STATE_SIGNAL);
};

export const getFoodPlansAction = () => async (dispatch) => {
  try {
    const config = getUserHeader();
    const res = await axios.get(FOOD_PLAN_ENDPOINT, config);
    dispatchAction(dispatch, GET_ALL_FOOD_PLANS_SUCCESS_SIGNAL, res.data);
  } catch (error) {
    dispatchAction(dispatch, GET_ALL_FOOD_PLANS_FAILED_SIGNAL);
    console.error(error);
  }
};
