import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TermsAndConditions from "../../../pages/public/TermsAndConditions";

function ModalTermsAndCondicions({ show, setShow }) {
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Términos y condiciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TermsAndConditions showTitle={false} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="warning" onClick={handleClose}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalTermsAndCondicions;
