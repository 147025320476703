import axios from "axios";
import { toast } from "react-toastify";
import { dispatchAction, getUserHeader } from "../../utils";
import { JOURNEYS_BY_AGENCY_ENDPOINT } from "../../endPoints";

const data = {
  journeys_info: [],
  journeyCreated: {},
  journeyToCreate: { passengers: [] }, // data for creating a new journey
  inProgress: false,
  creationSuccessful: false,
};

const RESET_JOURNEY_STATE_SIGNAL = "RESET_JOURNEY_STATE";
const ADD_PASSENGERS_FOR_JOURNEY_SIGNAL = "ADD_PASSENGERS_FOR_JOURNEY";
const GET_JOURNEYS_INFO_SUCCESS_SIGNAL = "GET_JOURNEYS_INFO_SUCCESS";
const GET_JOURNEYS_INFO_FAILED_SIGNAL = "GET_JOURNEYS_INFO_FAILED";

const CREATE_JOURNEY_SUCCESS_SIGNAL = "CREATE_JOURNEY_SUCCESS";
const CREATE_JOURNEY_FAILED_SIGNAL = "CREATE_JOURNEY_FAILED";

const SET_JOURNEY_CREATION_SIGNAL = "SET_JOURNEY_CREATION";
const REMOVE_JOURNEY_CREATION_SIGNAL = "REMOVE_JOURNEY_CREATION";

// eslint-disable-next-line default-param-last
export default function journeyReducer(state = data, action) {
  switch (action.type) {
    case GET_JOURNEYS_INFO_SUCCESS_SIGNAL:
      return { ...state, journeys_info: action.payload };

    case SET_JOURNEY_CREATION_SIGNAL:
      return { ...state, inProgress: true };

    case REMOVE_JOURNEY_CREATION_SIGNAL:
      return { ...state, inProgress: false };

    case ADD_PASSENGERS_FOR_JOURNEY_SIGNAL:
      return {
        ...state,
        journeyToCreate: {
          ...data.journeyToCreate,
          passengers: action.payload,
        },
      };

    case CREATE_JOURNEY_SUCCESS_SIGNAL:
      return {
        ...state,
        journeyCreated: action.payload,
        creationSuccessful: true,
      };

    case RESET_JOURNEY_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetJourneyStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_JOURNEY_STATE_SIGNAL);
};

export const getJourneysInfoAction = (agencyRuc) => async (dispatch) => {
  try {
    const config = getUserHeader();
    const endpoint = `${JOURNEYS_BY_AGENCY_ENDPOINT}/${agencyRuc}`;
    const res = await axios.get(endpoint, config);
    dispatchAction(dispatch, GET_JOURNEYS_INFO_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, GET_JOURNEYS_INFO_FAILED_SIGNAL);
  }
};

export const createJourneyAction = (journeyData) => async (dispatch) => {
  // create all regarding journey: Journey, JourneyGroup, Package, PackageService
  dispatch({ type: SET_JOURNEY_CREATION_SIGNAL });
  try {
    const config = getUserHeader();
    const endpoint = `${JOURNEYS_BY_AGENCY_ENDPOINT}/${journeyData.ruc_agency}`;
    const res = await axios.post(endpoint, journeyData, config);
    if (res.status === 200) toast.success("Paquete Creado");
    dispatchAction(dispatch, CREATE_JOURNEY_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, CREATE_JOURNEY_FAILED_SIGNAL);
    toast.error("Error al crear el Paquete");
  }
  dispatch({ type: REMOVE_JOURNEY_CREATION_SIGNAL });
};

export const addPassengersForJourneyAction =
  (passengers) => async (dispatch) => {
    dispatchAction(dispatch, ADD_PASSENGERS_FOR_JOURNEY_SIGNAL, passengers);
  };
