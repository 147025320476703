import axios from "axios";
import { toast } from "react-toastify";
import { dispatchAction, getHeaders } from "../../utils";
import {
  JOURNEYS_BY_PASSENGERS_BY_AGENCY_ENDPOINT,
  PASSENGERS_INFO_BY_AGENCY_ENDPOINT,
  PASSENGERS_IMAGES_ENDPOINT,
  PASSENGER_ENDPOINT,
} from "../../endPoints";

const defaultJourneysByPassenger = { nexts: [], previous: [] };
const data = {
  passengers_info: [],
  journeys_by_passenger: defaultJourneysByPassenger,
  passengers_to_create: { passengers: [], contact: {} },
  passengers_loading: false,
};

const GET_PASSENGERS_INFO_SUCCESS_SIGNAL = "GET_PASSENGERS_INFO_SUCCESS";
const GET_PASSENGERS_INFO_FAILED_SIGNAL = "GET_PASSENGERS_INFO_FAILED";

const JOURNEYS_BY_PASSENGER_SUCCESS_SIGNAL = "JOURNEYS_BY_PASSENGER_SUCCESS";
const JOURNEYS_BY_PASSENGER_FAILED_SIGNAL = "JOURNEYS_BY_PASSENGER_FAILED";

const RESET_JOURNEYS_BY_PASSENGER_SUCCESS_SIGNAL =
  "RESET_JOURNEYS_BY_PASSENGER_SUCCESS";
const RESET_JOURNEYS_BY_PASSENGER_FAILED_SIGNAL =
  "RESET_JOURNEYS_BY_PASSENGER_FAILED";

const SEND_PASSENGERS_IMAGES_SUCCESS_SIGNAL = "SEND_PASSENGERS_IMAGES_SUCCESS";
const SEND_PASSENGERS_IMAGES_FAILED_SIGNAL = "SEND_PASSENGERS_IMAGES_FAILED";

const ADD_PASSENGER_CONTACTS_SUCCESS_SIGNAL = "ADD_PASSENGER_CONTACTS_SUCCESS";
const ADD_PASSENGER_CONTACTS_FAILED_SIGNAL = "ADD_PASSENGER_CONTACTS_FAILED";

const CREATE_PASSENGER_SUCCESS_SIGNAL = "CREATE_PASSENGER_SUCCESS";
const CREATE_PASSENGER_FAILED_SIGNAL = "CREATE_PASSENGER_FAILED";

const ALL_PASSENGERS_CREATED_SUCCESS_SIGNAL = "ALL_PASSENGERS_CREATED_SUCCESS";
const ALL_PASSENGERS_CREATED_FAILED_SIGNAL = "ALL_PASSENGERS_CREATED_FAILED";

const SET_LOADING_ALL_PASSENGERS_SIGNAL = "SET_LOADING_ALL_PASSENGERS";
const REMOVE_LOADING_ALL_PASSENGERS_SIGNAL = "REMOVE_LOADING_ALL_PASSENGERS";

// eslint-disable-next-line default-param-last
export default function passengersInfoReducer(state = data, action) {
  switch (action.type) {
    case GET_PASSENGERS_INFO_SUCCESS_SIGNAL:
      return { ...state, passengers_info: action.payload };

    case SET_LOADING_ALL_PASSENGERS_SIGNAL:
      return { ...state, passengers_loading: true };

    case REMOVE_LOADING_ALL_PASSENGERS_SIGNAL:
      return { ...state, passengers_loading: false };

    case JOURNEYS_BY_PASSENGER_SUCCESS_SIGNAL:
      return { ...state, journeys_by_passenger: action.payload };

    case JOURNEYS_BY_PASSENGER_FAILED_SIGNAL:
    case RESET_JOURNEYS_BY_PASSENGER_SUCCESS_SIGNAL:
      return { ...state, journeys_by_passenger: defaultJourneysByPassenger };

    case ALL_PASSENGERS_CREATED_SUCCESS_SIGNAL:
    case ALL_PASSENGERS_CREATED_FAILED_SIGNAL:
      return data;

    case SEND_PASSENGERS_IMAGES_SUCCESS_SIGNAL:
      return {
        ...state,
        passengers_to_create: {
          ...state.passengers_to_create,
          passengers: [
            ...state.passengers_to_create.passengers,
            action.payload,
          ],
        },
      };

    case ADD_PASSENGER_CONTACTS_SUCCESS_SIGNAL:
      return {
        ...state,
        passengers_to_create: {
          ...state.passengers_to_create,
          contact: action.payload,
        },
      };

    case RESET_JOURNEYS_BY_PASSENGER_FAILED_SIGNAL:
    case CREATE_PASSENGER_SUCCESS_SIGNAL:
    case CREATE_PASSENGER_FAILED_SIGNAL:
    default:
      return state;
  }
}

export const getPassengersInfoAction = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_ALL_PASSENGERS_SIGNAL });
    const res = await axios.get(PASSENGERS_INFO_BY_AGENCY_ENDPOINT);
    dispatchAction(dispatch, GET_PASSENGERS_INFO_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, GET_PASSENGERS_INFO_FAILED_SIGNAL);
  }
  dispatch({ type: REMOVE_LOADING_ALL_PASSENGERS_SIGNAL });
};

export const getJourneysbyPassengersAction = (idNumber) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${JOURNEYS_BY_PASSENGERS_BY_AGENCY_ENDPOINT}/${idNumber}`
    );
    dispatchAction(dispatch, JOURNEYS_BY_PASSENGER_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, JOURNEYS_BY_PASSENGER_FAILED_SIGNAL);
  }
};

export const resetJourneysbyPassengersAction = () => async (dispatch) => {
  try {
    dispatchAction(dispatch, RESET_JOURNEYS_BY_PASSENGER_SUCCESS_SIGNAL);
  } catch {
    dispatchAction(dispatch, RESET_JOURNEYS_BY_PASSENGER_FAILED_SIGNAL);
  }
};

export const sendPassengerIDPhotosAction = (idImages) => async (dispatch) => {
  try {
    const formData = new FormData();
    Object.keys(idImages).forEach((field) => {
      if (!field.includes("URL")) formData.append(field, idImages[field]);
    });

    const config = getHeaders({ addFormData: true });
    const res = await axios.post(PASSENGERS_IMAGES_ENDPOINT, formData, config);
    dispatchAction(dispatch, SEND_PASSENGERS_IMAGES_SUCCESS_SIGNAL, res.data);
  } catch (error) {
    console.error(error);
    dispatchAction(dispatch, SEND_PASSENGERS_IMAGES_FAILED_SIGNAL);
  }
};

export const addPassegerContactsAction =
  (contact, emergencyContact) => async (dispatch) => {
    try {
      const contactData = { contact, emergency_contact: emergencyContact };
      dispatchAction(
        dispatch,
        ADD_PASSENGER_CONTACTS_SUCCESS_SIGNAL,
        contactData
      );
    } catch {
      dispatchAction(dispatch, ADD_PASSENGER_CONTACTS_FAILED_SIGNAL);
    }
  };

export const createPassengerAction = (passengerData) => async (dispatch) => {
  try {
    const config = getHeaders();
    const res = await axios.post(PASSENGER_ENDPOINT, passengerData, config);
    if (res.status === 201) {
      toast.success("Pasajero creado con éxito!");
      dispatchAction(dispatch, CREATE_PASSENGER_SUCCESS_SIGNAL, res.data);
    } else dispatchAction(dispatch, CREATE_PASSENGER_FAILED_SIGNAL);
  } catch {
    dispatchAction(dispatch, CREATE_PASSENGER_FAILED_SIGNAL);
    toast.error("Error en el servidor!");
  }
};

export const allPassengersCreatedAction = () => async (dispatch) => {
  try {
    dispatchAction(dispatch, ALL_PASSENGERS_CREATED_SUCCESS_SIGNAL);
  } catch {
    dispatchAction(dispatch, ALL_PASSENGERS_CREATED_FAILED_SIGNAL);
  }
};
