import React from "react";
import { Accordion, Button, Table } from "react-bootstrap";
import { openPDFInTab } from "../../../utils/pdf";
import { convertDatetimeString } from "../../../utils/utils";

const Invoices = ({ invoices, showPassengerCol = false }) => {
  if (invoices.length === 0) return null;

  return (
    <Accordion.Item eventKey={0}>
      <Accordion.Header>Facturas</Accordion.Header>

      <Accordion.Body>
        <Table bordered hover responsive size="sm">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Factura Personal</th>
              {showPassengerCol && <th>Pasajero</th>}
              <th>Valor</th>
              <th>Documento</th>
            </tr>
          </thead>

          <tbody>
            {invoices.map((invoice) => {
              const creationDate = convertDatetimeString(invoice.creation_date);

              return (
                <tr key={invoice.id}>
                  <td>{creationDate.date}</td>
                  <td>{invoice.is_passenger_invoice ? "Si" : "No"}</td>
                  {showPassengerCol && <td>{invoice.passenger || "------"}</td>}
                  <td>${invoice.total_cost}</td>
                  <td>
                    <Button
                      className="p-0"
                      variant="link"
                      onClick={() => openPDFInTab(invoice.file)}
                    >
                      Ver Factura
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default Invoices;
