import React from "react";
import { FaTiktok, FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { EmergencyWhatsAppURL } from "../utils/externalURLs";
import {
  ImportantInformationPath,
  TermsAndConditionsPath,
  PrivacyPolicyPath,
  AboutUsPath,
  QandAPath,
} from "../utils/paths";

const Footer = () => {
  const tiktok = "https://www.tiktok.com/@epicjourneyec?_t=8mYRoCOxSlB&_r=1";
  const linkedind = "https://www.linkedin.com/company/epicjourneyec/";
  const facebook = "https://www.facebook.com/profile.php?id=61558195611221";
  const twitter = "https://x.com/epicjourneyec";
  const instagram = "https://www.instagram.com/epicjourneyec";
  const linkClassName =
    "row justify-content-center justify-content-lg-between text-reset footer-link m-0 mb-1";

  return (
    <footer className="bg-dark text-center text-lg-start mt-5 p-2 text-light">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>
            Conectate con nosotros en nuestras redes y no te pierdas de nuestras
            promociones!
          </span>
        </div>

        <div>
          <a
            href={tiktok}
            target="_blank"
            aria-label="tiktok"
            rel="noopener noreferrer"
            className="text-light me-3 footer-link footer-link"
          >
            <FaTiktok size={25} />
          </a>
          <a
            href={instagram}
            target="_blank"
            rel="noopener noreferrer"
            className="text-light me-3 footer-link"
            aria-label="instagram"
          >
            <FaInstagram size={25} />
          </a>
          <a
            href={facebook}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="facebook"
            className="text-light me-3 footer-link"
          >
            <FaFacebookF size={25} />
          </a>
          <a
            href={twitter}
            target="_blank"
            aria-label="twitter"
            rel="noopener noreferrer"
            className="text-light me-3 footer-link"
          >
            <FaXTwitter size={25} />
          </a>
          <a
            href={linkedind}
            target="_blank"
            aria-label="linkedind"
            rel="noopener noreferrer"
            className="text-light footer-link"
          >
            <FaLinkedin size={25} />
          </a>
        </div>
      </section>

      <section className="">
        <Container className="text-center text-md-start mt-5">
          <Row className="mt-3">
            <Col md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h5 className="fw-bold mb-3">Epic Journey</h5>
              <p className="m-0 mb-1">
                Somos una agencia de viaje Ecuatoriana compremetidas con el uso
                de la técnologia para mejorar tu experiencia al viajar!
              </p>
            </Col>

            <Col md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h5 className="fw-bold mb-3">Conocenos</h5>

              <Link className={linkClassName} to={AboutUsPath}>
                Quienes Somos
              </Link>

              <Link className={linkClassName} to={ImportantInformationPath}>
                Información Importante
              </Link>

              <Link className={linkClassName} to={TermsAndConditionsPath}>
                Términos y Condiciones
              </Link>

              <Link className={linkClassName} to={PrivacyPolicyPath}>
                Políticas de Privacidad
              </Link>
            </Col>

            <Col md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h5 className="fw-bold mb-3">Información útil</h5>

              <Link
                target="_blank"
                className={linkClassName}
                to={EmergencyWhatsAppURL}
              >
                Contacto de Emergencia
              </Link>

              <Link className={linkClassName} to={QandAPath}>
                Preguntas Frecuentes
              </Link>
            </Col>

            <Col md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h5 className="fw-bold mb-3">Contactos</h5>
              <p className="m-0 mb-1">
                <FaLocationDot size={25} className="me-3" />
                Edificio Rafermartz, Kennedy Norte, Av. Luis Orrantia Cornejo 22
                - 23 y, Victor Hugo Sicouret Mz 109. Oficina 3A.
              </p>
              <p className="m-0 mb-1">
                <MdEmail size={25} className="me-3" />
                info@epicjourneyagency.com
              </p>
              <p className="m-0 mb-1">
                <IoLogoWhatsapp size={25} className="me-3" />
                +593 98 647 8477
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="text-center p-4">
        <p className="m-0">
          &copy; Guayaquil, Ecuador 2024 Epic Journey. Todos los derechos
          reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
