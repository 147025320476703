import React, { Fragment, useEffect, useState } from "react";
import { PiAirplaneTakeoffFill, PiAirplaneLandingFill } from "react-icons/pi";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Badge,
  Col,
  Dropdown,
  Alert,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { getMyJourneysInfoAction } from "../redux/ducks/passenger/journeysInfoDucks";
import JourneyPassengersList from "../components/passenger/JourneyPassengersList";
import AgencyServices from "../components/passenger/AgencyServices";
import { convertDatetimeString, getSingleNameFromPerson } from "../utils/utils";
import Billing from "../components/passenger/Billing";
import Loading from "./Loading";
import { getLuggageTypesAction } from "../redux/ducks/utils/luggageTypeDuck";
import { getAirlinesAction } from "../redux/ducks/utils/airlineDuck";
import { getFoodPlansAction } from "../redux/ducks/utils/foodPlanDuck";
import { getCarTypesAction } from "../redux/ducks/utils/carTypeDuck";
import { resetAllAgencyStateAction } from "../redux/ducks/agency/resetState";
import { getAllChoicesAction } from "../redux/ducks/utilsDucks";

/* eslint-disable camelcase */
const PassengerInformation = ({
  user,
  journeys,
  isLoading,
  allChoices,
  getMyJourneysInfoAPI,
  resetAllAgencyState,
  getLuggageTypesAPI,
  getAirlinesAPI,
  getFoodPlansAPI,
  getCarTypesAPI,
  getAllChoicesAPI,
}) => {
  const { service_directions, travel_directions } = allChoices;
  const [journeySelected, setJourneySelected] = useState(null);
  const [servicesFromJourney, setServicesFromJourney] = useState({});

  const getJournetTitle = () => {
    const start = journeySelected.start_city;
    const destination = journeySelected.destination_city;
    return `${start.country}, ${start.name}  -> ${destination.country}, ${destination.name} `;
  };

  const getJourneyTabTitle = (journey) => {
    const { date } = convertDatetimeString(journey.travel_date);
    const city = journey.destination_city.name;
    return `${date}, ${city}`;
  };

  useEffect(() => {
    getAllChoicesAPI();
    resetAllAgencyState();
    getLuggageTypesAPI();
    getAirlinesAPI();
    getFoodPlansAPI();
    getCarTypesAPI();
  }, []);

  useEffect(() => {
    if (user) getMyJourneysInfoAPI(user.id);
  }, [user]);

  useEffect(() => {
    if (journeys && journeys.length > 0) {
      setJourneySelected(journeys[0]);
      setServicesFromJourney(journeys[0].services);
    }
  }, [journeys]);

  const onClickToChangeJourney = (index) => {
    setJourneySelected(journeys[index]);
    setServicesFromJourney(journeys[index].services);
  };

  if (isLoading) return <Loading />;

  return journeySelected ? (
    <Container className="mt-5">
      <Row>
        <Col className="mb-5">
          <Container>
            <Row className="mb-3">
              <DropdownButton
                as={ButtonGroup}
                id="dropdown-variants-warning"
                variant="warning"
                className="p-0"
                title={getJourneyTabTitle(journeySelected)}
              >
                {journeys.map((journey, index) => (
                  <Dropdown.Item
                    key={index}
                    eventKey={index}
                    active={journeySelected.journey_id === journey.journey_id}
                    onClick={() => onClickToChangeJourney(index)}
                  >
                    {getJourneyTabTitle(journey)}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Row>

            <Row className="mb-2">
              <h4>Grupo de viaje</h4>
            </Row>

            <Row>
              <JourneyPassengersList journeyInfo={journeySelected} />
            </Row>

            {/* <Row className="mt-5">
              <Container>
                <Row>
                  <Col>
                    Ultimo viaje: <strong>Dubai </strong>
                    <Badge bg="secondary">Hace 11 meses</Badge>
                  </Col>
                </Row>
                <Button className="mt-2" variant="warning" disabled>
                  Ver todos mis viajes
                </Button>
              </Container>
            </Row> */}
          </Container>
        </Col>

        <Col md={9}>
          <Row className="mb-2">
            <Col md={10} style={{ color: "black" }}>
              <h5 className="mb-0">
                <PiAirplaneTakeoffFill className="me-3" size={30} />
                {getJournetTitle()}
              </h5>

              <Badge bg="warning" style={{ color: "black" }}>
                {travel_directions[journeySelected.travel_direction]}
              </Badge>

              <Badge className="ms-3" bg="secondary">
                Faltan {journeySelected.days_to_travel} días
              </Badge>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={12} lg={4}>
              <strong>Asesor/a: </strong>
              {getSingleNameFromPerson(journeySelected.travel_agent).name}
            </Col>
            <Col xs={12} lg={4}>
              <strong>Fecha Compra: </strong>
              {journeySelected.purchase_date}
            </Col>
            <Col xs={12} lg={4}>
              <strong>Costo Total: </strong>${journeySelected.cost}
            </Col>
          </Row>

          <h5>Servicios Contratados</h5>
          <AgencyServices
            services={servicesFromJourney}
            serviceDirections={service_directions}
            passengers={journeySelected.passengers}
            countryStart={journeySelected.start_city.country}
            countryDestination={journeySelected.destination_city.country}
          />

          {journeySelected.invoices.length + journeySelected.payments.length >
            0 && <h5 className="mt-4">Facturación</h5>}
          <Billing
            invoices={journeySelected.invoices}
            payments={journeySelected.payments}
          />
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="mt-5">
      <Alert variant="warning">
        Usted aun no tiene viajes registrados. Comuníquese con su agente para
        obtener mas información
      </Alert>
    </Container>
  );
};
/* eslint-enable camelcase */

const mapStateToProps = (state) => ({
  user: state.auth.user,
  journeys: state.myJourneys.journeys_info,
  isLoading: state.myJourneys.isLoading,
  allChoices: state.utils.choices,
});

const actions = {
  resetAllAgencyState: resetAllAgencyStateAction,
  getMyJourneysInfoAPI: getMyJourneysInfoAction,
  getLuggageTypesAPI: getLuggageTypesAction,
  getAllChoicesAPI: getAllChoicesAction,
  getAirlinesAPI: getAirlinesAction,
  getFoodPlansAPI: getFoodPlansAction,
  getCarTypesAPI: getCarTypesAction,
};

export default connect(mapStateToProps, actions)(PassengerInformation);
