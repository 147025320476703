import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { PackagePath, CalendarPath, PassengersPath } from "../../utils/paths";

function AdminNav({ setIsExpanded }) {
  return (
    <Fragment>
      <Link
        className="nav-link"
        to={PassengersPath}
        onClick={() => setIsExpanded(false)}
      >
        Pasajeros
      </Link>

      {/* <Link
        className="nav-link"
        to={PackagePath}
        onClick={() => setIsExpanded(false)}
      >
        Paquetes
      </Link> */}

      <Link
        className="nav-link"
        to={CalendarPath}
        onClick={() => setIsExpanded(false)}
      >
        Calendario
      </Link>
    </Fragment>
  );
}

export default AdminNav;
