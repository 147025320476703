import React from "react";
import { useNavigate } from "react-router-dom";
import { FiUsers } from "react-icons/fi";
import { TbMessages } from "react-icons/tb";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { whatsAppURL } from "../../utils/externalURLs";
import { LoginPath } from "../../utils/paths";

const NeedHelp = () => {
  const navigate = useNavigate();
  const openWhatsApp = () => window.open(whatsAppURL, "_blank");
  const goToLogin = () => navigate(LoginPath);

  return (
    <Container className="p-5 mt-5 mb-5 bg-light" fluid>
      <Container className="mt-5 mb-5">
        <h2 className="text-center main-text-color fw-bold">
          Soporte y Asistencia
        </h2>
        <h3 className="text-center gray-text-color">
          ¿Ya tienes una reserva y necesitas ayuda?
        </h3>

        <Row className="mt-5">
          <Col md={6} className="mb-4 mt-5">
            <Card className="h-100">
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <TbMessages size={50} />
                  <Card.Title>Contáctanos</Card.Title>
                </div>
                <Card.Text>
                  Gestiona tus solicitudes y cotizaciones con uno de nuestros
                  agentes del Servicio de Atención al Cliente.
                </Card.Text>
                <Button
                  variant="warning"
                  className="w-100"
                  onClick={openWhatsApp}
                >
                  Contactar ahora
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} className="mb-4  mt-5">
            <Card className="h-100">
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <FiUsers size={50} />
                  <Card.Title>Consulta tu Viaje</Card.Title>
                </div>
                <Card.Text>
                  Gestiona todas reservas desde la plataforma de Epic Journey.
                  <strong> Todo en el mismo lugar!</strong> Aqui podrás
                  encontrar todos tus documentos de tus viajes, rápido y fácil!
                </Card.Text>
                <Button variant="warning" className="w-100" onClick={goToLogin}>
                  Iniciar sesión
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default NeedHelp;
