import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import {
  createAirTicketAction,
  updateAirTicketAction,
} from "../../../../redux/ducks/agency/airTicketDuck";
import { convertDatetimeString } from "../../../../utils/utils";
import { getJourneyAirpotsByCityAction } from "../../../../redux/ducks/utils/airportDuck";
import { validateDateGTToday } from "../../../../utils/validators";

/* eslint-disable camelcase */
const AirTicketForm = ({
  getJourneyAirpotsByCityAPI,
  updateAirTicketAPI,
  createAirTicketAPI,
  travelDirection,
  cityStart,
  cityDestination,
  airTicketState,
  aiports,
  luggageTypes,
  agencyRuc,
  dataInput = null,
  packageServiceID = null,
}) => {
  const { created, updated } = airTicketState;
  const updateTask = dataInput !== null;
  const blockRestrictedFields = updateTask;
  const buttonName = updateTask ? "Actualizar" : "Guardar";
  const commonData = { agency: agencyRuc };
  const defaultData = {
    cost: 0,
    reservation_code: "",
    flight_number: "----",
    arrival_date: "",
    arrival_hour: "",
    boarding_date: "",
    boarding_hour: "",
    departure_airport: "",
    destination_airport: "",
    luggage_types: "",
    ...commonData,
  };
  const [data, setData] = useState(defaultData);
  const [validated, setValidated] = useState(false);
  const [dataSended, setDataSended] = useState(false);
  const blockAllFields = (created || updated) !== null && dataSended;

  const { airportByCountryStart, airportByCountryDestination } = aiports;
  let startAirports = airportByCountryStart;
  let detinationsAirports = airportByCountryDestination;
  if (travelDirection === "V") {
    startAirports = airportByCountryDestination;
    detinationsAirports = airportByCountryStart;
  }

  const validatedFiedls = {
    cost: data.cost >= 0,
    reservation_code: data.reservation_code !== "",
    flight_number: data.flight_number !== "",
    arrival_date: validateDateGTToday(data.arrival_date) || updateTask,
    boarding_date: validateDateGTToday(data.boarding_date) || updateTask,
    arrival_hour: data.arrival_hour !== "",
    boarding_hour: data.boarding_hour !== "",
    departure_airport: data.departure_airport !== "",
    destination_airport: data.destination_airport !== "",
    luggage_types: data.luggage_types !== "",
    reservation: Boolean(data.reservation) || updateTask,
  };
  const isFormValidated = Object.values(validatedFiedls).every((val) => val);

  useEffect(() => {
    getJourneyAirpotsByCityAPI(cityStart.code, cityDestination.code);
  }, []);

  useEffect(() => {
    if (updateTask) {
      const tmpData = { ...dataInput, ...commonData };
      const arrival = convertDatetimeString(tmpData.arrival_time);
      const boarding = convertDatetimeString(tmpData.boarding_time);
      tmpData.arrival_date = arrival.dateInput;
      tmpData.boarding_date = boarding.dateInput;
      tmpData.arrival_hour = arrival.time;
      tmpData.boarding_hour = boarding.time;

      tmpData.departure_airport = tmpData.departure_airport.id;
      tmpData.destination_airport = tmpData.destination_airport.id;
      tmpData.luggage_types = tmpData.luggage_types[0].id;
      delete tmpData.reservation;
      setData(tmpData);
    }
  }, [dataInput]);

  const handleAddReservationFile = (event) => {
    const file = event.target.files[0];
    if (file) setData({ ...data, reservation: file });
  };

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const sendData = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDataSended(true);

    const form = event.currentTarget;
    const formIsValid = form.checkValidity();
    if (!(isFormValidated && formIsValid) && !updateTask) return;
    setValidated(true);

    const {
      arrival_date,
      arrival_hour,
      boarding_date,
      boarding_hour,
      ...dataStructured
    } = data;
    dataStructured.arrival_time = `${arrival_date} ${arrival_hour}`;
    dataStructured.boarding_time = `${boarding_date} ${boarding_hour}`;

    if (updateTask) updateAirTicketAPI(dataStructured);
    else createAirTicketAPI(dataStructured, packageServiceID);
  };

  return (
    <Form noValidate validated={validated} onSubmit={sendData}>
      <Container>
        <Row>
          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formReservationCode">
              <Form.Label>Código de Reserva</Form.Label>
              <Form.Control
                name="reservation_code"
                value={data.reservation_code}
                onChange={handleInputChange}
                disabled={blockRestrictedFields || blockAllFields}
                isInvalid={dataSended && !validatedFiedls.reservation_code}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formFlightNumber">
              <Form.Label>Número de Vuelo</Form.Label>
              <Form.Control
                name="flight_number"
                value={data.flight_number}
                onChange={handleInputChange}
                disabled={blockAllFields}
                isInvalid={dataSended && !validatedFiedls.flight_number}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formDepartureAirport">
              <Form.Label>Aeropuerto Salida</Form.Label>
              <Form.Select
                disabled={blockRestrictedFields || blockAllFields}
                onChange={handleInputChange}
                value={data.departure_airport}
                name="departure_airport"
                isInvalid={dataSended && !validatedFiedls.departure_airport}
                required
              >
                <option>Seleccione</option>
                {startAirports.map((airport) => (
                  <option key={airport.id} value={airport.id}>
                    {airport.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formDestinationAirport">
              <Form.Label>Aeropuerto Llegada</Form.Label>
              <Form.Select
                disabled={blockRestrictedFields || blockAllFields}
                onChange={handleInputChange}
                value={data.destination_airport}
                name="destination_airport"
                isInvalid={dataSended && !validatedFiedls.destination_airport}
                required
              >
                <option>Seleccione</option>
                {detinationsAirports.map((airport) => (
                  <option key={airport.id} value={airport.id}>
                    {airport.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formLuggageType">
              <Form.Label>Tipo de Equipaje</Form.Label>
              <Form.Select
                disabled={blockAllFields}
                onChange={handleInputChange}
                value={data.luggage_types}
                name="luggage_types"
                isInvalid={dataSended && !validatedFiedls.luggage_types}
                required
              >
                <option>Seleccione</option>
                {luggageTypes.map((luggage) => (
                  <option key={luggage.id} value={luggage.id}>
                    {luggage.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formBoardingDate">
              <Form.Label>Fecha Salida</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                name="boarding_date"
                onChange={handleInputChange}
                value={data.boarding_date}
                isInvalid={dataSended && !validatedFiedls.boarding_date}
              />
              <Form.Control.Feedback type="invalid">
                La fecha debe ser mayor o igual al día de hoy
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formBoardinghour">
              <Form.Label>Hora Salida</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="time"
                name="boarding_hour"
                onChange={handleInputChange}
                value={data.boarding_hour}
                isInvalid={dataSended && !validatedFiedls.boarding_hour}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formArrivalDate">
              <Form.Label>Fecha Llegada</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                name="arrival_date"
                onChange={handleInputChange}
                value={data.arrival_date}
                isInvalid={dataSended && !validatedFiedls.arrival_date}
              />
              <Form.Control.Feedback type="invalid">
                La fecha debe ser mayor o igual al día de hoy
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formArrivalhour">
              <Form.Label>Hora Llegada</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="time"
                name="arrival_hour"
                onChange={handleInputChange}
                value={data.arrival_hour}
                isInvalid={dataSended && !validatedFiedls.arrival_hour}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={12} lg={6} className="mb-4 mb-lg-2">
            <Form.Group controlId="formReservation">
              <Form.Label>Reserva</Form.Label>
              <Form.Control
                onChange={handleAddReservationFile}
                disabled={blockAllFields}
                type="file"
                required
                isInvalid={dataSended && !validatedFiedls.reservation}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4 mb-lg-2">
            <Form.Group controlId="formCost">
              <Form.Label>Costo</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  disabled={blockAllFields}
                  type="number"
                  onChange={handleInputChange}
                  value={data.cost}
                  name="cost"
                  isInvalid={dataSended && !validatedFiedls.cost}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} lg={{ offset: 2, span: 2 }} className="mt-4">
            <Button
              className="w-100"
              variant="warning"
              type="submit"
              disabled={blockAllFields}
            >
              {buttonName}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
/* eslint-enable camelcase */

const mapStateToProps = (state) => ({
  luggageTypes: state.luggageType.all,
  airTicketState: state.airTicket,
  agencyRuc: state.agency.ruc,
  aiports: state.airport,
});

const actions = {
  getJourneyAirpotsByCityAPI: getJourneyAirpotsByCityAction,
  createAirTicketAPI: createAirTicketAction,
  updateAirTicketAPI: updateAirTicketAction,
};

export default connect(mapStateToProps, actions)(AirTicketForm);
