import React from "react";
import { Accordion, Button, Table } from "react-bootstrap";
import { openPDFInTab } from "../../../utils/pdf";
import { convertDatetimeString } from "../../../utils/utils";

const Payments = ({ payments }) => {
  if (payments.length === 0) return null;

  return (
    <Accordion.Item eventKey={1}>
      <Accordion.Header>Pagos</Accordion.Header>

      <Accordion.Body>
        <Table bordered hover responsive size="sm">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Metodo de Pago</th>
              <th>Valor</th>
              <th>Documento</th>
            </tr>
          </thead>

          <tbody>
            {payments.map((payment) => {
              const creationDate = convertDatetimeString(payment.creation_date);

              return (
                <tr key={payment.id}>
                  <td>{creationDate.date}</td>
                  <td>{payment.method}</td>
                  <td>${payment.value}</td>
                  <td>
                    <Button
                      className="p-0"
                      variant="link"
                      onClick={() => openPDFInTab(payment.voucher)}
                    >
                      Ver Pago
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default Payments;
