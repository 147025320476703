import React from "react";
import { Container } from "react-bootstrap";
import { IoLogoWhatsapp } from "react-icons/io";
import TravelGallery from "../components/home/TravelGallery";
import NeedHelp from "../components/home/NeedHelp";
import Benefits from "../components/home/Benefits";
import { whatsAppURL } from "../utils/externalURLs";
import QuoteNow from "../components/home/QuoteNow";
import ImagesSection from "../components/home/ImagesSection";

function Home() {
  const openWhatsApp = () => window.open(whatsAppURL, "_blank");

  return (
    <Container fluid className="m-0 p-0">
      <IoLogoWhatsapp
        className="btn add-static-btn p-0"
        onClick={openWhatsApp}
        size={60}
        color="#075E54"
      />

      <ImagesSection />
      <Benefits />
      <TravelGallery />
      <NeedHelp />
      <QuoteNow />
    </Container>
  );
}

export default Home;
