import React from "react";
import { Button, Modal, Container, Row } from "react-bootstrap";
import { openPDFInTab } from "../../utils/pdf";

function ModalTicketsByPassenger({ data, show, setShow }) {
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Documentos Por Pasajero</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {data.map((doc) => (
            <Row key={doc.id}>
              <Button
                className="mb-2"
                variant="warning"
                onClick={() => openPDFInTab(doc.document)}
              >
                {doc.name}
              </Button>
            </Row>
          ))}
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalTicketsByPassenger;
