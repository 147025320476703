import axios from "axios";
import { toast } from "react-toastify";
import { updateToCompletePackageServiceAction } from "../journey/packageServiceDuck";
import { createLocationAction, updateLocationAction } from "../utils/location";
import { dispatchAction, getUserHeader, getFormData } from "../../utils";
import { HOSTING_ENDPOINT } from "../../endPoints";

const data = {
  created: null,
  updated: null,
};

const CREATE_HOSTING_SUCCESS_SIGNAL = "CREATE_HOSTING_SUCCESS";
const CREATE_HOSTING_FAILED_SIGNAL = "CREATE_HOSTING_FAILED";
const RESET_HOSTING_STATE_SIGNAL = "RESET_HOSTING_STATE_SIGNAL";

const UPDATE_HOSTING_SUCCESS_SIGNAL = "UPDATE_HOSTING_SUCCESS";
const UPDATE_HOSTING_FAILED_SIGNAL = "UPDATE_HOSTING_FAILED";

// eslint-disable-next-line default-param-last
export default function hostingReducer(state = data, action) {
  switch (action.type) {
    case CREATE_HOSTING_SUCCESS_SIGNAL:
      return { ...state, created: action.payload };

    case UPDATE_HOSTING_SUCCESS_SIGNAL:
      return { ...state, updated: action.payload };

    case RESET_HOSTING_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetHostingStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_HOSTING_STATE_SIGNAL);
};

export const createHostingAction =
  (payload, location, packageServiceID) => async (dispatch) => {
    try {
      const newPayload = { ...payload };
      const locationData = await createLocationAction(payload.name, location);
      if (locationData.status !== 201) {
        return;
      }

      newPayload.location = locationData.data.id;
      const formData = getFormData(newPayload);
      const config = getUserHeader({ addFormData: true });
      const res = await axios.post(HOSTING_ENDPOINT, formData, config);
      if (res.status === 201) {
        toast.success("Hospedaje creado");
        dispatchAction(dispatch, UPDATE_HOSTING_SUCCESS_SIGNAL, res.data);
        if (packageServiceID)
          dispatch(
            updateToCompletePackageServiceAction(packageServiceID, res.data.id)
          );
      }
    } catch (error) {
      dispatchAction(dispatch, UPDATE_HOSTING_FAILED_SIGNAL);
      toast.error("Error al crear el Hospedaje");
    }
  };

export const updateHostingAction =
  (payload, location, locID) => async (dispatch) => {
    try {
      const locData = await updateLocationAction(locID, payload.name, location);
      if (locData.status !== 200) return;

      const formData = getFormData(payload);
      const endpoint = `${HOSTING_ENDPOINT}${payload.id}/`;
      const config = getUserHeader({ addFormData: true });
      const res = await axios.patch(endpoint, formData, config);
      if (res.status === 200) {
        toast.success("Hospedaje Actualizado");
        dispatchAction(dispatch, CREATE_HOSTING_SUCCESS_SIGNAL, res.data);
      }
    } catch (error) {
      dispatchAction(dispatch, CREATE_HOSTING_FAILED_SIGNAL);
      toast.error("Error al actualizar el Hospedaje");
    }
  };
