import React, { useState } from "react";
import { Container, Accordion, Col, Row, Button } from "react-bootstrap";
import CarRentalForm from "../../admin/newJourney/services/CarRentalForm";
import { convertDatetimeString } from "../../../utils/utils";
import ModalUpdateService from "../ModalUpdateService";
import { pendingHeader, pendingInfo } from "./utils";
import { openPDFInTab } from "../../../utils/pdf";

/* eslint-disable camelcase */
const CarRental = ({ data, isAdmin }) => {
  if (!data) return null;
  const [showModalUpdateService, setShowModalUpdateService] = useState(false);
  const [create, setCreate] = useState(false); // create service in case it doesnt exist
  const { id, status, service, service_name } = data;
  const {
    cost,
    reservation,
    operator,
    manager_name,
    capacity,
    delivery_date,
    return_date,
    car_type,
  } = service || {};

  const car_data = car_type && `${car_type.branch}, ${car_type.model}`;
  const deliveryDate = convertDatetimeString(delivery_date).date;
  const returnDate = convertDatetimeString(return_date).date;

  const handleCreateService = () => {
    setCreate(true);
    setShowModalUpdateService(true);
  };

  return (
    <Accordion.Item eventKey={id}>
      {showModalUpdateService && (
        <ModalUpdateService
          show={showModalUpdateService}
          setShow={setShowModalUpdateService}
          create
        >
          <CarRentalForm
            dataInput={create ? null : service}
            packageServiceID={id}
          />
        </ModalUpdateService>
      )}

      <Accordion.Header>
        {service_name.name} {status === "P" && pendingHeader()}
      </Accordion.Header>

      <Accordion.Body>
        {status === "P" && pendingInfo(isAdmin, handleCreateService)}

        {service && (
          <Container className="p-0">
            <Row>
              <Col lg={10}>
                <Container className="p-0">
                  <Row className="mb-1">
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Operador:</strong> {operator}
                    </Col>

                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Propietario:</strong> {manager_name}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Tipo de carro:</strong> {car_data}
                    </Col>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Capacidad:</strong> {capacity}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Hora Entrega:</strong>{" "}
                      {deliveryDate.toLocaleString()}
                    </Col>
                    <Col xs={12} lg={6} className="mb-2">
                      <strong>Hora Retorno:</strong>{" "}
                      {returnDate.toLocaleString()}
                    </Col>
                  </Row>

                  {isAdmin && (
                    <Row>
                      <Col xs={12} lg={6} className="mb-1">
                        <strong>Costo:</strong> ${cost}
                      </Col>
                    </Row>
                  )}
                </Container>
              </Col>

              <Col>
                <Container>
                  <Row>
                    <Button
                      className="mb-2"
                      variant="warning"
                      onClick={() => openPDFInTab(reservation)}
                    >
                      Reservación
                    </Button>

                    {isAdmin && (
                      <Button
                        variant="warning"
                        onClick={() => setShowModalUpdateService(true)}
                      >
                        Actualizar
                      </Button>
                    )}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

/* eslint-enable camelcase */

export default CarRental;
