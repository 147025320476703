export function dispatchAction(dispatch, signal, data = undefined) {
  const dataAction = { type: signal };
  if (data !== undefined) dataAction.payload = data;
  dispatch(dataAction);
}

export function getHeaders(options = { addFormData: false }) {
  const { addFormData } = options;
  const headers = {
    Accept: "application/json",
    Authorization: `JWT ${localStorage.getItem("access")}`,
  };

  if (addFormData) headers["Content-Type"] = "multipart/form-data";

  const config = { headers };
  return config;
}

export function getUserHeader() {
  return getHeaders();
}

export function getFormData(payload) {
  const formData = new FormData();
  Object.keys(payload).forEach((field) => {
    formData.append(field, payload[field]);
  });

  return formData;
}
