import axios from "axios";
import { dispatchAction, getUserHeader } from "../../utils";
import { AIRLINE_ENDPOINT } from "../../endPoints";

const data = {
  all: [],
};

const GET_ALL_AIRLINES_SUCCESS_SIGNAL = "GET_ALL_AIRLINES_SUCCESS";
const GET_ALL_AIRLINES_FAILED_SIGNAL = "GET_ALL_AIRLINES_FAILED";
const RESET_AIRLINE_STATE_SIGNAL = "RESET_AIRLINE_STATE_SIGNAL";

// eslint-disable-next-line default-param-last
export default function airlineReducer(state = data, action) {
  switch (action.type) {
    case GET_ALL_AIRLINES_SUCCESS_SIGNAL:
      return { ...state, all: action.payload };

    case RESET_AIRLINE_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetAirlineStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_AIRLINE_STATE_SIGNAL);
};

export const getAirlinesAction = () => async (dispatch) => {
  try {
    const config = getUserHeader();
    const res = await axios.get(AIRLINE_ENDPOINT, config);
    dispatchAction(dispatch, GET_ALL_AIRLINES_SUCCESS_SIGNAL, res.data);
  } catch (error) {
    dispatchAction(dispatch, GET_ALL_AIRLINES_FAILED_SIGNAL);
    console.error(error);
  }
};
