import axios from "axios";
import { dispatchAction } from "../../utils";

const data = {
  ruc: "0993385802001",
};

// eslint-disable-next-line default-param-last
export default function agencyReducer(state = data, action) {
  switch (action.type) {
    default:
      return state;
  }
}
