import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { LoginPath } from "../utils/paths";

const PrivateRoute = ({ auth }) => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page

  const component = () => {
    if (auth.access_token === null) return <Navigate to={LoginPath} />;

    if (!auth.isLoading && auth.isAuth) {
      if (auth.isAuth) return <Outlet />;
      return <Navigate to={LoginPath} />;
    }
    return null;
  };

  return component();
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
