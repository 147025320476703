export function convertDatetimeString(datetimeString) {
  // Ejemplo '2024-05-20T23:32:53.148726-05:00'

  // Obtener la fecha y hora
  const dateObject = new Date(datetimeString);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1; // Los meses en JavaScript son 0-indexed
  const day = dateObject.getDate();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  // Formatear la fecha y hora en un string
  const monthStr = month.toString().padStart(2, "0");
  const dayStr = day.toString().padStart(2, "0");
  const hourStr = hours.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");

  const formattedDate = `${dayStr}/${monthStr}/${year}`;
  const formattedTime = `${hourStr}:${minutesStr}:${secondsStr}`;
  const dateInput = `${year}-${monthStr}-${dayStr}`;

  return {
    dateInput,
    date: formattedDate,
    time: formattedTime,
  };
}

export function getSingleNameAndLastName(firstNames, lastNames) {
  const name = firstNames.split(" ")[0];
  const lastName = lastNames.split(" ")[0];
  return { full: `${name} ${lastName}`, name, last_name: lastName };
}

export function getSingleNameFromPerson(person) {
  return getSingleNameAndLastName(person.first_name, person.last_name);
}
