import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import {
  createTransportationAction,
  updateTransportationAction,
} from "../../../../redux/ducks/agency/transportationDuck";

const TransportationForm = ({
  updateTransportationAPI,
  createTransportationAPI,
  transportationState,
  luggageTypes,
  agencyRuc,
  dataInput = null,
  packageServiceID = null,
}) => {
  const { created, updated } = transportationState;
  const updateTask = dataInput !== null;
  const blockRestrictedFields = updateTask;
  const blockAllFields = (created || updated) !== null;
  const buttonName = updateTask ? "Actualizar" : "Guardar";
  const commonData = { agency: agencyRuc };
  const defaultData = {
    cost: 0,
    operator: "",
    phone: "",
    capacity: "",
    start_location: "",
    destination: "",
    arrival_date: "",
    arrival_hour: "",
    boarding_date: "",
    boarding_hour: "",
    luggage_type: "",
    ...commonData,
  };

  const [data, setData] = useState(defaultData);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (updateTask) {
      const tmpData = { ...dataInput, ...commonData };
      delete tmpData.reservation;
      setData(tmpData);
    }
  }, [dataInput]);

  const handleAddReservationFile = (event) => {
    const file = event.target.files[0];
    if (file) setData({ ...data, reservation: file });
  };

  const handleInputChange = (event) => {
    const fieldName = event.target.name;
    var { value } = event.target;
    setData({ ...data, [fieldName]: value });
  };

  const sendData = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log(data);

    if (updateTask) updateTransportationAPI(data);
    else createTransportationAPI(data, packageServiceID);
  };

  return (
    <Form noValidate validated={validated} onSubmit={sendData}>
      <Container>
        <Row>
          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formOperator">
              <Form.Label>Operador</Form.Label>
              <Form.Control
                name="operator"
                value={data.operator}
                onChange={handleInputChange}
                disabled={blockRestrictedFields || blockAllFields}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formPhone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                disabled={blockRestrictedFields || blockAllFields}
                onChange={handleInputChange}
                value={data.phone}
                name="phone"
                required
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formCapacity">
              <Form.Label>Capacidad</Form.Label>
              <Form.Control
                type="number"
                disabled={blockRestrictedFields || blockAllFields}
                onChange={handleInputChange}
                value={data.capacity}
                name="capacity"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formBoardingDate">
              <Form.Label>Fecha Salida</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                name="boarding_date"
                onChange={handleInputChange}
                value={data.boarding_date}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formBoardinghour">
              <Form.Label>Hora Salida</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="time"
                name="boarding_hour"
                onChange={handleInputChange}
                value={data.boarding_hour}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formArrivalDate">
              <Form.Label>Fecha Llegada</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                name="arrival_date"
                onChange={handleInputChange}
                value={data.arrival_date}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formArrivalhour">
              <Form.Label>Hora Salida</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="time"
                name="arrival_hour"
                onChange={handleInputChange}
                value={data.arrival_hour}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formLuggageType">
              <Form.Label>Tipo de Equipaje</Form.Label>
              <Form.Select
                disabled={blockRestrictedFields || blockAllFields}
                onChange={handleInputChange}
                value={data.luggage_type}
                name="luggage_type"
                required
              >
                <option>Seleccione</option>
                {luggageTypes.map((luggage) => (
                  <option key={luggage.id} value={luggage.id}>
                    {luggage.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formStartLocation">
              <Form.Label>Ubicación Recogida</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                placeholder="Latitud, Longuitud"
                onChange={handleInputChange}
                value={data.start_location}
                name="start_location"
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={4} className="mb-4">
            <Form.Group controlId="formDestination">
              <Form.Label>Ubicación Destino</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                placeholder="Latitud, Longuitud"
                onChange={handleInputChange}
                value={data.destination}
                name="destination"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={12} lg={6} className="mb-4 mb-lg-2">
            <Form.Group controlId="formReservation">
              <Form.Label>Reserva</Form.Label>
              <Form.Control
                onChange={handleAddReservationFile}
                disabled={blockAllFields}
                type="file"
                required
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4 mb-lg-2">
            <Form.Group controlId="formCost">
              <Form.Label>Costo</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  disabled={blockAllFields}
                  type="number"
                  onChange={handleInputChange}
                  value={data.cost}
                  name="cost"
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} lg={{ offset: 2, span: 2 }} className="mt-4">
            <Button
              className="w-100"
              variant="warning"
              type="submit"
              disabled={blockAllFields}
            >
              {buttonName}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  transportationState: state.transportation,
  luggageTypes: state.luggageType.all,
  agencyRuc: state.agency.ruc,
});

const actions = {
  createTransportationAPI: createTransportationAction,
  updateTransportationAPI: updateTransportationAction,
};

export default connect(mapStateToProps, actions)(TransportationForm);
