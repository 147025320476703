import { toast } from "react-toastify";
import axios from "axios";
import { dispatchAction, getUserHeader } from "../utils";
import { getEmailByUserAction, resetUserState } from "./userDucks";
import {
  SINGUP_ENDPOINT,
  GET_USER_INFO_ENDPOINT,
  GET_USER_TOKEN_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  CHANGE_PASSWORD_ENDPOINT,
  USER_ACTIVATION_ENDPOINT,
  CHECK_AUTHENTITATION_ENDPOINT,
  REFRESS_ACCESS_TOKEN_ENDPOINT,
  RESET_PASSWORD_CONFIRM_ENDPOINT,
} from "../endPoints";

const defaultData = {
  access_token: null,
  refresh_token: null,
  isAuth: false,
  isLoading: false,
  changePasswordInProgress: false,
  changePassworddSuccessful: false,
  user: {
    isActive: false,
    username: null,
    isAdmin: false,
  },
};

const data = {
  ...defaultData,
  access_token: localStorage.getItem("access"),
  refresh_token: localStorage.getItem("refresh"),
};

const SINGUP_AUTH_SIGNAL = "SINGUP_AUTH";
const SINGUP_FAILED_SIGNAL = "SINGUP_FAILED";

const SET_AUTH_LOADING_SIGNAL = "SET_AUTH_LOADING";
const REMOVE_AUTH_LOADING_SIGNAL = "REMOVE_AUTH_LOADING";

const ACTIVATION_SUCCESS_SIGNAL = "ACTIVATION_AUTH";
const ACTIVATION_FAILED_SIGNAL = "ACTIVATION_FAILED";

const LOGIN_SUCCESS_SIGNAL = "LOGIN_SUCCESS";
const LOGIN_FAILED_SIGNAL = "LOGIN_FAILED";
const LOGOUT_SIGNAL = "LOGOUT";

const USER_LOADER_SUCCESS_SIGNAL = "USER_LOADER_SUCCESS";
const USER_LOADER_FAILED_SIGNAL = "USER_LOADER_FAILED";

const AUTHENTICATED_SUCCESS_SIGNAL = "AUTHENTICATED_SUCCESS";
const AUTHENTICATED_FAILED_SIGNAL = "AUTHENTICATED_FAILED";

const REFRESH_TOKEN_SUCCESS_SIGNAL = "REFRESH_TOKEN_SUCCESS";
const REFRESH_TOKEN_FAILED_SIGNAL = "REFRESH_TOKEN_FAILED";

const RESET_PASSWORD_SUCCESS_SIGNAL = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAILED_SIGNAL = "RESET_PASSWORD_FAILED";
const RESET_PASSWORD_CONFIRM_SUCCESS_SIGNAL = "RESET_PASSWORD_CONFIRM_SUCCESS";
const RESET_PASSWORD_CONFIRM_FAILED_SIGNAL = "RESET_PASSWORD_CONFIRM_FAILED";

const SET_CHANGE_PASSWORD_LOADING_SIGNAL = "SET_CHANGE_PASSWORD_LOADING";
const REMOVE_CHANGE_PASSWORD_LOADING_SIGNAL = "REMOVE_CHANGE_PASSWORD_LOADING";

const CHANGE_PASSWORD_SUCCESS_SIGNAL = "CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAILED_SIGNAL = "CHANGE_PASSWORD_FAILED";

// eslint-disable-next-line default-param-last
export default function authReducer(state = data, action) {
  switch (action.type) {
    case SET_AUTH_LOADING_SIGNAL:
      return { ...state, isLoading: true };

    case REMOVE_AUTH_LOADING_SIGNAL:
      return { ...state, isLoading: false };

    case ACTIVATION_SUCCESS_SIGNAL:
      return { ...state, isActivated: true };

    case LOGIN_SUCCESS_SIGNAL:
      localStorage.setItem("access", action.payload.access);
      localStorage.setItem("refresh", action.payload.refresh);
      return {
        ...state,
        access_token: localStorage.getItem("access"),
        refresh_token: localStorage.getItem("refresh"),
      };

    case REFRESH_TOKEN_SUCCESS_SIGNAL:
      localStorage.setItem("access", action.payload.access);
      return {
        ...state,
        access_token: localStorage.getItem("access"),
      };

    case AUTHENTICATED_SUCCESS_SIGNAL:
      return state;

    case USER_LOADER_SUCCESS_SIGNAL:
      return { ...state, isAuth: true, user: action.payload };

    case SINGUP_AUTH_SIGNAL:
      return { ...state, ...action.payload };

    case LOGOUT_SIGNAL:
    case REFRESH_TOKEN_FAILED_SIGNAL:
    case AUTHENTICATED_FAILED_SIGNAL:
    case USER_LOADER_FAILED_SIGNAL:
    case ACTIVATION_FAILED_SIGNAL:
    case LOGIN_FAILED_SIGNAL:
    case SINGUP_FAILED_SIGNAL:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return defaultData;

    case CHANGE_PASSWORD_SUCCESS_SIGNAL:
    case RESET_PASSWORD_CONFIRM_SUCCESS_SIGNAL:
      return { ...state, changePassworddSuccessful: true };

    case SET_CHANGE_PASSWORD_LOADING_SIGNAL:
      return { ...state, changePasswordInProgress: true };

    case REMOVE_CHANGE_PASSWORD_LOADING_SIGNAL:
      return { ...state, changePasswordInProgress: false };

    case RESET_PASSWORD_SUCCESS_SIGNAL:
    case RESET_PASSWORD_FAILED_SIGNAL:
    case RESET_PASSWORD_CONFIRM_FAILED_SIGNAL:
    default:
      return state;
  }
}

const setAuthLoading = async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING_SIGNAL });
};

const removeAuthLoading = async (dispatch) => {
  dispatch({ type: REMOVE_AUTH_LOADING_SIGNAL });
};

export const loadUserInfoAction = () => async (dispatch) => {
  setAuthLoading(dispatch);
  if (localStorage.getItem("access")) {
    try {
      const configUser = getUserHeader();
      const res = await axios.get(GET_USER_INFO_ENDPOINT, configUser);
      if (res.status === 200) {
        const userData = res.data;
        const isActive = userData.is_active;
        const isAdmin = userData.is_staff;
        delete userData.is_active;
        delete userData.is_staff;
        const userDataFormatted = {
          ...userData,
          isActive,
          isAdmin,
        };
        dispatchAction(dispatch, USER_LOADER_SUCCESS_SIGNAL, userDataFormatted);
      }
    } catch {
      dispatchAction(dispatch, USER_LOADER_FAILED_SIGNAL);
    }
  } else dispatchAction(dispatch, USER_LOADER_FAILED_SIGNAL);
  removeAuthLoading(dispatch);
};

export const logInAction = (username, password) => async (dispatch) => {
  setAuthLoading(dispatch);
  try {
    const userData = { username, password };
    const res = await axios.post(GET_USER_TOKEN_ENDPOINT, userData);
    if (res.status === 200) {
      dispatchAction(dispatch, LOGIN_SUCCESS_SIGNAL, res.data);
      dispatch(loadUserInfoAction());
    }
  } catch {
    dispatchAction(dispatch, LOGIN_FAILED_SIGNAL);
    toast.error("Cédula o contraseña Incorrecta");
  }
  removeAuthLoading(dispatch);
};

export const singUpAction =
  (username, password, rePassword, email, firstName, lastName) =>
  async (dispatch) => {
    setAuthLoading(dispatch);
    const userData = {
      username,
      password,
      rePassword,
      email,
      firstName,
      lastName,
    };
    const res = await axios.post(SINGUP_ENDPOINT, userData);
    if (res.status === 201)
      dispatchAction(dispatch, SINGUP_AUTH_SIGNAL, res.data);
    else dispatchAction(dispatch, SINGUP_FAILED_SIGNAL);
    removeAuthLoading(dispatch);
  };

export const userActivationAction = (uid, token) => async (dispatch) => {
  setAuthLoading(dispatch);
  try {
    const userData = { uid, token };
    const res = await axios.post(USER_ACTIVATION_ENDPOINT, userData);
    if (res.status === 204)
      dispatchAction(dispatch, ACTIVATION_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, ACTIVATION_FAILED_SIGNAL);
    toast.error("Error al activar la cuenta");
  }
  removeAuthLoading(dispatch);
};

export const checkAuthenticationAction = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    try {
      const tmpData = { token: localStorage.getItem("access") };
      const res = await axios.post(CHECK_AUTHENTITATION_ENDPOINT, tmpData);
      if (res.status === 200)
        dispatchAction(dispatch, AUTHENTICATED_SUCCESS_SIGNAL);
    } catch {
      dispatchAction(dispatch, AUTHENTICATED_FAILED_SIGNAL);
    }
  } else {
    console.debug("There is not access token");
    dispatchAction(dispatch, AUTHENTICATED_FAILED_SIGNAL);
  }
};

export const refershAuthenticationAction = () => async (dispatch) => {
  if (localStorage.getItem("refresh")) {
    try {
      const tmpData = { refresh: localStorage.getItem("refresh") };
      const res = await axios.post(REFRESS_ACCESS_TOKEN_ENDPOINT, tmpData);
      if (res.status === 200)
        dispatchAction(dispatch, REFRESH_TOKEN_SUCCESS_SIGNAL, res.data);
    } catch {
      dispatchAction(dispatch, REFRESH_TOKEN_FAILED_SIGNAL);
    }
  } else {
    console.debug("There is not refresh token");
    dispatchAction(dispatch, REFRESH_TOKEN_FAILED_SIGNAL);
  }
};

export const logoutAction = () => async (dispatch) => {
  dispatchAction(dispatch, LOGOUT_SIGNAL);
  dispatch(resetUserState());
};

export const resetPasswordAction = (idNumber) => async (dispatch) => {
  try {
    const payload = { username: idNumber };
    const res = await axios.post(RESET_PASSWORD_ENDPOINT, payload);
    dispatch(getEmailByUserAction(idNumber));
    if (res.status === 204)
      dispatchAction(dispatch, RESET_PASSWORD_SUCCESS_SIGNAL);
    else dispatchAction(dispatch, RESET_PASSWORD_FAILED_SIGNAL);
  } catch {
    dispatchAction(dispatch, RESET_PASSWORD_FAILED_SIGNAL);
    toast.error("Usuario no existe");
  }
};

export const changePasswordAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_CHANGE_PASSWORD_LOADING_SIGNAL });
  try {
    const config = getUserHeader();
    const res = await axios.post(CHANGE_PASSWORD_ENDPOINT, payload, config);
    if (res.status === 204) {
      toast.success("Contaseña Actualizada Exitosamente");
      dispatchAction(dispatch, CHANGE_PASSWORD_SUCCESS_SIGNAL, res.data);
    } else dispatchAction(dispatch, CHANGE_PASSWORD_FAILED_SIGNAL);
  } catch (error) {
    const errors = error.response.data;
    Object.values(errors).forEach((errorArray) => {
      errorArray.forEach((e) => toast.error(e));
    });
    dispatchAction(dispatch, CHANGE_PASSWORD_FAILED_SIGNAL);
  }
  dispatch({ type: REMOVE_CHANGE_PASSWORD_LOADING_SIGNAL });
};

export const resetPasswordConfirmAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_CHANGE_PASSWORD_LOADING_SIGNAL });
  const { new_password: newPassword, re_new_password: newRePassword } = payload;
  if (newPassword !== newRePassword) {
    toast.error("Las contraseñas no coinciden");
    return;
  }
  try {
    const res = await axios.post(RESET_PASSWORD_CONFIRM_ENDPOINT, payload);
    if (res.status === 204) {
      dispatchAction(dispatch, RESET_PASSWORD_CONFIRM_SUCCESS_SIGNAL);
      toast.success("Contraseña cambiada exitosamente");
    }
  } catch {
    dispatchAction(dispatch, RESET_PASSWORD_CONFIRM_FAILED_SIGNAL);
    toast.error("Error al recuperar contraseña");
  }
  dispatch({ type: REMOVE_CHANGE_PASSWORD_LOADING_SIGNAL });
};
