import React, { useState } from "react";
import { Container, Accordion, Col, Row, Button } from "react-bootstrap";
import TravelInsuranceForm from "../../admin/newJourney/services/TravelInsuranceForm";
import ModalUpdateService from "../ModalUpdateService";
import { pendingHeader, pendingInfo } from "./utils";
import { openPDFInTab } from "../../../utils/pdf";

/* eslint-disable camelcase */
const TravelInsurance = ({
  data,
  isAdmin,
  setServiceIDSelected,
  setShowTicketsByPassenger,
  cityDestination, // for creation
}) => {
  if (!data) return null;
  const [showModalUpdateService, setShowModalUpdateService] = useState(false);
  const [create, setCreate] = useState(false); // create service in case it doesnt exist
  const { id, status, service, service_name } = data;
  const { reservation, operator, cost, start_date, end_date, city } =
    service || {};
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  const handleClickSeePassengerTickets = () => {
    setServiceIDSelected(id);
    setShowTicketsByPassenger(true);
  };

  const handleCreateService = () => {
    setCreate(true);
    setShowModalUpdateService(true);
  };

  return (
    <Accordion.Item eventKey={id}>
      {showModalUpdateService && (
        <ModalUpdateService
          show={showModalUpdateService}
          setShow={setShowModalUpdateService}
          create
        >
          <TravelInsuranceForm
            dataInput={create ? null : service}
            cityDestination={cityDestination}
            packageServiceID={id}
          />
        </ModalUpdateService>
      )}

      <Accordion.Header>
        {service_name.name} {status === "P" && pendingHeader()}
      </Accordion.Header>

      <Accordion.Body>
        {status === "P" && pendingInfo(isAdmin, handleCreateService)}

        {service && (
          <Container className="p-0">
            <Row>
              <Col lg={10}>
                <Container className="p-0">
                  <Row className="mb-1">
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Operador:</strong> {operator}
                    </Col>

                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Ciudad:</strong> {city.country}, {city.name}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Fecha Inicio:</strong>{" "}
                      {startDate.toLocaleString()}
                    </Col>
                    <Col xs={12} lg={6} className="mb-2">
                      <strong>Fecha Fin:</strong> {endDate.toLocaleString()}
                    </Col>
                  </Row>

                  {isAdmin && (
                    <Row>
                      <Col xs={12} lg={6} className="mb-1">
                        <strong>Costo:</strong> ${cost}
                      </Col>
                    </Row>
                  )}
                </Container>
              </Col>

              <Col>
                <Container>
                  <Row>
                    <Button
                      className="mb-2"
                      variant="warning"
                      onClick={() => openPDFInTab(reservation)}
                    >
                      Reservación
                    </Button>

                    <Button
                      className="mb-2"
                      variant="warning"
                      onClick={handleClickSeePassengerTickets}
                    >
                      Ver Polizas
                    </Button>

                    {isAdmin && (
                      <Button
                        variant="warning"
                        onClick={() => setShowModalUpdateService(true)}
                      >
                        Actualizar
                      </Button>
                    )}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

/* eslint-enable camelcase */

export default TravelInsurance;
