import axios from "axios";
import { MY_JOURNEYS_INFO_ENDPOINT } from "../../endPoints";
import { dispatchAction, getUserHeader } from "../../utils";

const data = {
  journeys_info: [],
  isLoading: false,
};

const GET_MY_JOURNEYS_INFO_SUCCESS_SIGNAL = "GET_MY_JOURNEYS_INFO_SUCCESS";
const GET_MY_JOURNEYS_INFO_FAILED_SIGNAL = "GET_MY_JOURNEYS_INFO_FAILED";

const SET_LOADING_MY_JOURNEYS_SIGNAL = "SET_LOADING_MY_JOURNEYS";
const REMOVE_LOADING_MY_JOURNEYS_SIGNAL = "REMOVE_LOADING_MY_JOURNEYS";

// eslint-disable-next-line default-param-last
export default function journeyInfoReducer(state = data, action) {
  switch (action.type) {
    case GET_MY_JOURNEYS_INFO_SUCCESS_SIGNAL:
      return { ...state, journeys_info: action.payload };

    case SET_LOADING_MY_JOURNEYS_SIGNAL:
      return { ...state, isLoading: true };

    case REMOVE_LOADING_MY_JOURNEYS_SIGNAL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}

export const getMyJourneysInfoAction = (passengerID) => async (dispatch) => {
  dispatch({ type: SET_LOADING_MY_JOURNEYS_SIGNAL });
  try {
    const conf = getUserHeader();
    const res = await axios.get(
      `${MY_JOURNEYS_INFO_ENDPOINT}/${passengerID}`,
      conf
    );
    dispatchAction(dispatch, GET_MY_JOURNEYS_INFO_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, GET_MY_JOURNEYS_INFO_FAILED_SIGNAL);
  }
  dispatch({ type: REMOVE_LOADING_MY_JOURNEYS_SIGNAL });
};
