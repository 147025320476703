import React, { useState, useEffect } from "react";
import { Container, Accordion, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import Hosting from "./services/Hosting";
import AirTicket from "./services/AirTicket";
import Activity from "./services/Activity";
import Visa from "./services/Visa";
import CarRental from "./services/CarRental";
import Cruise from "./services/Cruise";
import TravelInsurance from "./services/TravelInsurance";
import Transportation from "./services/Transportation";
import ModalTicketsByPassenger from "./ModalTicketsByPassenger";

/* eslint-disable react/jsx-props-no-spreading */
const AgencyServices = ({
  services,
  passengers,
  isAdmin,
  countryStart,
  cityStart,
  cityDestination,
  countryDestination,
  serviceDirections,
  showAirportReminder = false,
  isAdminPage = false,
}) => {
  const [showTicketsByPassenger, setShowTicketsByPassenger] = useState(false);
  const [serviceIDSelected, setServiceIDSelected] = useState(null);
  const [ticketsByPassengerData, setTicketsByPassengerData] = useState([]);

  // filter service passengers docs based on serviceIDselected
  useEffect(() => {
    if (serviceIDSelected) {
      let servicesData = passengers.map((p) =>
        p.service_docs.map((doc) => {
          const name = `${p.first_name.split(" ")[0]} ${p.last_name}`;
          const docInfo = { ...doc, name };
          return docInfo;
        })
      );
      // flatten array: [[dict1, dict2], [dict3]] a [dict1, dict2, dict3]
      servicesData = servicesData.reduce((acc, c) => acc.concat(c), []);
      const dataFiltered = servicesData.filter(
        (s) => s.package_service === serviceIDSelected
      );
      setTicketsByPassengerData(dataFiltered);
    }
  }, [serviceIDSelected]);

  const {
    visa,
    carrental,
    airticket,
    activity,
    cruise,
    hosting,
    travelinsurance,
    transportation,
  } = services;

  const commondProps = {
    isAdmin: isAdminPage && isAdmin,
    serviceDirections,
    cityStart,
    cityDestination,
  };
  const passengerServiveArgs = {
    setShowTicketsByPassenger,
    setServiceIDSelected,
  };

  return (
    <Container>
      {airticket && showAirportReminder && (
        <Alert variant="warning">
          Su viaje está cerca. Recuerde estar 3h antes en el aeropuerto.
        </Alert>
      )}

      <Accordion>
        {airticket &&
          airticket.map((data) => (
            <AirTicket
              key={data.id}
              data={data}
              countryStart={countryStart}
              countryDestination={countryDestination}
              {...commondProps}
              {...passengerServiveArgs}
            />
          ))}

        {transportation &&
          transportation.map((data) => (
            <Transportation key={data.id} data={data} {...commondProps} />
          ))}

        {hosting &&
          hosting.map((data) => (
            <Hosting key={data.id} data={data} {...commondProps} />
          ))}

        {activity &&
          activity.map((data) => (
            <Activity key={data.id} data={data} {...commondProps} />
          ))}

        {carrental &&
          carrental.map((data) => (
            <CarRental key={data.id} data={data} {...commondProps} />
          ))}

        {cruise &&
          cruise.map((data) => (
            <Cruise key={data.id} data={data} {...commondProps} />
          ))}

        {visa &&
          visa.map((data) => (
            <Visa key={data.id} data={data} {...commondProps} />
          ))}

        {travelinsurance &&
          travelinsurance.map((data) => (
            <TravelInsurance
              key={data.id}
              data={data}
              {...commondProps}
              {...passengerServiveArgs}
            />
          ))}
      </Accordion>

      {showTicketsByPassenger && (
        <ModalTicketsByPassenger
          data={ticketsByPassengerData}
          show={showTicketsByPassenger}
          setShow={setShowTicketsByPassenger}
        />
      )}
    </Container>
  );
};
/* eslint-enable react/jsx-props-no-spreading */

const mapStateToProps = (state) => ({
  isAdmin: state.auth.user.isAdmin,
});

export default connect(mapStateToProps, null)(AgencyServices);
