import axios from "axios";
import { toast } from "react-toastify";
import { dispatchAction, getUserHeader, getFormData } from "../../utils";
import { PACKAGE_SERVICE_ENDPOINT } from "../../endPoints";

const data = {
  created: null,
  updated: null,
};

const RESET_PACKAGE_SERVICE_STATE_SIGNAL = "RESET_PACKAGE_SERVICE_STATE_SIGNAL";
const UPDATE_PACKAGE_SERVICE_SUCCESS_SIGNAL = "UPDATE_PACKAGE_SERVICE_SUCCESS";
const UPDATE_PACKAGE_SERVICE_FAILED_SIGNAL = "UPDATE_PACKAGE_SERVICE_FAILED";

// eslint-disable-next-line default-param-last
export default function packageServiceReducer(state = data, action) {
  switch (action.type) {
    case UPDATE_PACKAGE_SERVICE_SUCCESS_SIGNAL:
      return { ...state, updated: action.payload };

    case RESET_PACKAGE_SERVICE_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetPackageServiceStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_PACKAGE_SERVICE_STATE_SIGNAL);
};

export const updatePackageServiceAction = (payload) => async (dispatch) => {
  try {
    const formData = getFormData(payload);
    const endpoint = `${PACKAGE_SERVICE_ENDPOINT}${payload.id}/`;
    const config = getUserHeader({ addFormData: true });
    const res = await axios.patch(endpoint, formData, config);
    if (res.status === 200) {
      toast.success("Servicio Actualizado");
      dispatchAction(dispatch, UPDATE_PACKAGE_SERVICE_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, UPDATE_PACKAGE_SERVICE_FAILED_SIGNAL);
    toast.error("Error al actualizar el Servicio");
  }
};

export const updateToCompletePackageServiceAction =
  (id, serviceID) => async (dispatch) => {
    const payload = { id, status: "T", service: serviceID };
    dispatch(updatePackageServiceAction(payload));
  };
