import axios from "axios";
import { toast } from "react-toastify";
import { dispatchAction, getUserHeader, getFormData } from "../../utils";
import { PAYMENT_ENDPOINT } from "../../endPoints";

const data = {
  created: null,
  updated: null,
};

const CREATE_PAYMENT_SUCCESS_SIGNAL = "CREATE_PAYMENT_SUCCESS";
const CREATE_PAYMENT_FAILED_SIGNAL = "CREATE_PAYMENT_FAILED";
const RESET_PAYMENT_STATE_SIGNAL = "RESET_PAYMENT_STATE_SIGNAL";

const UPDATE_PAYMENT_SUCCESS_SIGNAL = "UPDATE_PAYMENT_SUCCESS";
const UPDATE_PAYMENT_FAILED_SIGNAL = "UPDATE_PAYMENT_FAILED";

// eslint-disable-next-line default-param-last
export default function paymentReducer(state = data, action) {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS_SIGNAL:
      return { ...state, created: action.payload };

    case UPDATE_PAYMENT_SUCCESS_SIGNAL:
      return { ...state, updated: action.payload };

    case RESET_PAYMENT_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetPaymentStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_PAYMENT_STATE_SIGNAL);
};

export const createPaymentAction = (payload) => async (dispatch) => {
  try {
    const formData = getFormData(payload);
    const config = getUserHeader({ addFormData: true });
    const res = await axios.post(PAYMENT_ENDPOINT, formData, config);
    if (res.status === 201) {
      toast.success("Pago creado");
      dispatchAction(dispatch, CREATE_PAYMENT_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, CREATE_PAYMENT_FAILED_SIGNAL);
    toast.error("Error al crear el Pago");
  }
};

export const updatePaymentAction = (payload) => async (dispatch) => {
  try {
    const formData = getFormData(payload);
    const endpoint = `${PAYMENT_ENDPOINT}${payload.id}/`;
    const config = getUserHeader({ addFormData: true });
    const res = await axios.patch(endpoint, formData, config);
    if (res.status === 200) {
      toast.success("Pago Actualizado");
      dispatchAction(dispatch, UPDATE_PAYMENT_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, UPDATE_PAYMENT_FAILED_SIGNAL);
    toast.error("Error al actualizar el Pago");
  }
};
