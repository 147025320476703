import axios from "axios";
import { dispatchAction, getUserHeader } from "../../utils";
import { EMPLOYEE_ENDPOINT } from "../../endPoints";

const data = {
  data: {},
};

const GET_EMPLOYEE_INFO_SUCCESS_SIGNAL = "GET_EMPLOYEE_INFO_SUCCESS";
const GET_EMPLOYEE_INFO_FAILED_SIGNAL = "GET_EMPLOYEE_INFO_FAILED";

// eslint-disable-next-line default-param-last
export default function employeeReducer(state = data, action) {
  switch (action.type) {
    case GET_EMPLOYEE_INFO_SUCCESS_SIGNAL:
      return { ...state, data: action.payload };

    default:
      return state;
  }
}

export const getEmployeeInfoAction =
  (agencyRUC, userID) => async (dispatch) => {
    try {
      const config = getUserHeader();
      const endpoint = `${EMPLOYEE_ENDPOINT}/${agencyRUC}/${userID}`;
      const res = await axios.get(endpoint, config);
      dispatchAction(dispatch, GET_EMPLOYEE_INFO_SUCCESS_SIGNAL, res.data);
    } catch (error) {
      dispatchAction(dispatch, GET_EMPLOYEE_INFO_FAILED_SIGNAL);
      console.error(error);
    }
  };
