import React from "react";
import { Container, Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { VIEW_URL, QandAPath } from "../../utils/paths";

const QandA = () => {
  const style = { textAlign: "justify" };
  return (
    <Container className="mt-5">
      <h1 className="mb-4">Preguntas Frecuentes</h1>

      <Helmet>
        <title>Preguntas Frecuentes</title>
        <link rel="canonical" href={`${VIEW_URL}${QandAPath}`} />
      </Helmet>

      <Container>
        <Accordion>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>¿Cómo puedo pagar?</Accordion.Header>
            <Accordion.Body>
              <Container style={style}>
                En <strong>Epic Journey S.A.S</strong> usted podrá realizar su
                pago con los medios habilitados desde transferencias/ depositos
                bancarios e interbancarios a nuestras cuentas corrientes:
                <ul>
                  <li>Banco Pacifico</li>
                  <li>Banco Pichincha</li>
                  <li>Banco Produbanco</li>
                  <li>Paypal</li>
                </ul>
                Además, de opción de pago escogidas por el comercio, podrá pagar
                a través (Diners, Discover, Visa y MasterCard); de todos los
                bancos para tal fin con pago corriente y en los diferido.
              </Container>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey={1}>
            <Accordion.Header>
              ¿Cómo sé que mi reserva está confirmada?
            </Accordion.Header>
            <Accordion.Body>
              <Container style={style}>
                <ul>
                  <li>
                    En general, la confirmación de la reserva se realiza a
                    través de WhatsApp y un correo de confirmación justo después
                    de la compra. Si no lo encuentras, recuerda revisar el buzón
                    de correo no deseado o spam.
                  </li>

                  <li>
                    También tendrás acceso a la página web
                    <strong> Consulta Mi Viaje </strong>
                    para consultar todos los detalles de tu viaje, y confirmar
                    los servicios contratados con nosotros.
                  </li>
                </ul>
                En el caso en que no sea posible confirmar inmediatamente tu
                reserva (porque has solicitado un servicio especial, por
                ejemplo), te informaremos por WhatsApp o correo. Además de, el
                acceso a esta información en{" "}
                <a href="https://www.epicjourneyagency.com">
                  www.epicjourneyagency.com
                </a>{" "}
                en cuanto la reserva quede confirmada. En caso de reserva de
                hotel y servicios contratados en destino, el voucher se cargarà
                7 días antes del viaje.
              </Container>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey={2}>
            <Accordion.Header>Requisitos de viaje</Accordion.Header>
            <Accordion.Body>
              <Container style={style}>
                <p>
                  Los requisitos de viaje para ingresar en cada destino pueden
                  cambiar sin previo aviso en cualquier momento, ya que son
                  dictados por los gobiernos de cada país. Recomendamos revisar
                  sus requisitos de viaje periódicamente para mantenerse al
                  tanto de cualquier cambio o nuevo requerimiento que pueda
                  impactar su viaje, esta información la puedes validar junto a
                  tu asesor de viaje.
                </p>
                <p className="fw-bold">
                  Recuerda también revisar demás requisitos migratorios y
                  sanitarios para tu viaje, tales como: pasaporte, visas,
                  tarjetas de turismo, vacuna de fiebre amarilla y cualquier
                  documento adicional o especial que se exija por las
                  disposiciones legales de los países de partida, tránsito y
                  destino.
                </p>
                <a href="https://www.traveldoc.aero/">www.traveldoc.aero</a>{" "}
                Aqui puedes validar los requisitos actualizados
              </Container>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey={3}>
            <Accordion.Header>
              Cómo añadir equipaje a la reserva
            </Accordion.Header>
            <Accordion.Body>
              <Container style={style}>
                <p>
                  Durante el proceso de reserva, antes de finalizar tu compra,
                  puedes añadir el equipaje que desees, siempre de acuerdo a las
                  opciones y tarifas que ofrece la aerolínea.
                </p>
                <p>
                  Después de haber reservado, puedes hacerlo directamente con tu
                  asesor de viaje. Generalmente la solicitud se gestiona al
                  momento. De ser necesario, te enviaremos un presupuesto y la
                  tramitaremos en un plazo de 3 días, dando prioridad a las
                  fechas de salida más próximas.
                </p>
                <p>
                  Si has adquirido un vuelo en Oferta Especial, cuando solicites
                  añadir equipaje, te comunicaremos si puede hacerse antes de la
                  salida o directamente en el aeropuerto. Ten en cuenta que
                  algunos tipos de billete no permiten añadir equipaje. En estos
                  casos, te recomendamos que contactes directamente con la
                  aerolínea.
                </p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </Container>
  );
};

export default QandA;
