import React from "react";
import { Container, Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Spinner
        className="m-3"
        animation="border"
        style={{ width: "3rem", height: "3rem" }}
      />
      <h2>Loading...</h2>
    </Container>
  );
};

export default Loading;
