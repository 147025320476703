import React from "react";
import { Row, Button, ListGroup, Accordion } from "react-bootstrap";
import { openPDFInTab } from "../../utils/pdf";

/* eslint-disable camelcase */
const JourneyPassengersList = ({ journeyInfo }) => {
  if (!journeyInfo) return null;
  const { need_migration_docs, passengers } = journeyInfo;

  // TODO
  return need_migration_docs && false ? (
    <Accordion>
      {passengers.map((passenger, index) => (
        <Accordion.Item eventKey={index} key={index}>
          <Accordion.Header>
            {passenger.first_name} {passenger.last_name}
          </Accordion.Header>
          <Accordion.Body>
            {passenger.migration_docs.map((doc) => (
              <Row className="mb-2" key={doc.id}>
                <Button
                  variant="warning"
                  onClick={() => openPDFInTab(doc.document)}
                >
                  {doc.migration_document.country},{" "}
                  {doc.migration_document.name}
                </Button>
              </Row>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  ) : (
    <ListGroup>
      {passengers.map((passenger, index) => (
        <ListGroup.Item key={index}>
          {passenger.first_name} {passenger.last_name}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};
/* eslint-enable camelcase */

export default JourneyPassengersList;
