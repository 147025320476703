import axios from "axios";
import { toast } from "react-toastify";
import { dispatchAction, getUserHeader } from "../utils";
import {
  USER_ENDPOINT,
  PERSON_ENDPOINT,
  USER_CONTACT_ENDPOINT,
  EMAIL_BY_USER_ENDPOINT,
  PERSONAL_DATA_ENDPOINT,
  PASSENGERS_IMAGES_ENDPOINT,
  EMERGENCY_CONTACT_ENDPOINT,
  PERSONAL_CONTACTS_ENDPOINT,
} from "../endPoints";

const data = {
  data: null,
  contacts: null,
  updateInProgress: false,
  updatedSuccessful: false,
  email_to_restore_password: null,
};

const RESET_STATE_SIGNAL = "RESET_USER_STATE";
const GET_USER_INFO_SUCCESS_SIGNAL = "GET_USER_INFO_SUCCESS";
const GET_USER_INFO_FAILED_SIGNAL = "GET_USER_INFO_FAILED";

const UPDATE_USER_SUCCESS_SIGNAL = "UPDATE_USER_SUCCESS";
const UPDATE_USER_FAILED_SIGNAL = "UPDATE_USER_FAILED";

const SET_USER_UPDATE_LOADING_SIGNAL = "SET_USER_UPDATE_LOADING";
const REMOVE_USER_UPDATE_LOADING_SIGNAL = "REMOVE_USER_UPDATE_LOADING";

const GET_USER_CONTACT_SUCCESS_SIGNAL = "GET_USER_CONTACT_SUCCESS";
const GET_USER_CONTACT_FAILED_SIGNAL = "GET_USER_CONTACT_FAILED";

const GET_EMAIL_BY_USER_SUCCESS_SIGNAL = "GET_EMAIL_BY_USER_SUCCESS";
const GET_EMAIL_BY_USER_FAILED_SIGNAL = "GET_EMAIL_BY_USER_FAILED";

// eslint-disable-next-line default-param-last
export default function userReducer(state = data, action) {
  switch (action.type) {
    case GET_USER_INFO_SUCCESS_SIGNAL:
      return { ...state, data: action.payload };

    case GET_USER_CONTACT_SUCCESS_SIGNAL:
      return { ...state, contacts: action.payload };

    case UPDATE_USER_SUCCESS_SIGNAL:
      return { ...state, updatedSuccessful: true };

    case SET_USER_UPDATE_LOADING_SIGNAL:
      return { ...state, updateInProgress: true };

    case REMOVE_USER_UPDATE_LOADING_SIGNAL:
      return { ...state, updateInProgress: false };

    case GET_EMAIL_BY_USER_SUCCESS_SIGNAL:
      return { ...state, email_to_restore_password: action.payload };

    case GET_EMAIL_BY_USER_FAILED_SIGNAL:
      return { ...state, email_to_restore_password: null };

    case RESET_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

const setUserUpdateLoading = async (dispatch) => {
  dispatch({ type: SET_USER_UPDATE_LOADING_SIGNAL });
};

const removeUserUpdateLoading = async (dispatch) => {
  dispatch({ type: REMOVE_USER_UPDATE_LOADING_SIGNAL });
};

export const resetUserState = () => async (dispatch) => {
  dispatch({ type: RESET_STATE_SIGNAL });
};

export const getUserInfoAction = (userID) => async (dispatch) => {
  try {
    const res = await axios.get(`${PERSONAL_DATA_ENDPOINT}/${userID}`);
    dispatchAction(dispatch, GET_USER_INFO_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, GET_USER_INFO_FAILED_SIGNAL);
  }
};

export const updateUserAction = (dataUser) => async (dispatch) => {
  try {
    setUserUpdateLoading(dispatch);
    const config = getUserHeader();
    const { contacts, personal, photos, user } = dataUser;
    const { general: generalContacts, emergency: emergencyContacts } = contacts;

    const personRes = await axios.put(
      `${PERSON_ENDPOINT}${personal.id_number}/`,
      personal,
      config
    );

    const photosRes = await axios.put(
      `${PASSENGERS_IMAGES_ENDPOINT}${photos.id}/`,
      photos,
      config
    );

    const contactRes = await axios.put(
      `${USER_CONTACT_ENDPOINT}${generalContacts.id}/`,
      generalContacts,
      config
    );

    const userRes = await axios.put(
      `${USER_ENDPOINT}${user.id}/`,
      user,
      config
    );

    const emergencyRes = await axios.put(
      `${EMERGENCY_CONTACT_ENDPOINT}${emergencyContacts.id}/`,
      emergencyContacts,
      config
    );

    if (
      personRes.status === 200 &&
      photosRes.status === 200 &&
      contactRes.status === 200 &&
      userRes.status === 200 &&
      emergencyRes.status === 200
    ) {
      dispatchAction(dispatch, UPDATE_USER_SUCCESS_SIGNAL);
    } else toast.error("Error al actualizar datos!");
  } catch {
    dispatchAction(dispatch, UPDATE_USER_FAILED_SIGNAL);
    toast.error("Error en el servidor");
  }
  removeUserUpdateLoading(dispatch);
};

export const getUserContactsAction = (idNumber) => async (dispatch) => {
  try {
    const res = await axios.get(`${PERSONAL_CONTACTS_ENDPOINT}/${idNumber}`);
    dispatchAction(dispatch, GET_USER_CONTACT_SUCCESS_SIGNAL, res.data);
  } catch {
    dispatchAction(dispatch, GET_USER_CONTACT_FAILED_SIGNAL);
  }
};

export const getEmailByUserAction = (idNumber) => async (dispatch) => {
  setUserUpdateLoading(dispatch);
  try {
    const res = await axios.get(`${EMAIL_BY_USER_ENDPOINT}/${idNumber}`);
    const { data: tmpData, status } = res;
    if (status === 200)
      dispatchAction(dispatch, GET_EMAIL_BY_USER_SUCCESS_SIGNAL, tmpData.email);
  } catch {
    dispatchAction(dispatch, GET_EMAIL_BY_USER_FAILED_SIGNAL);
    toast.error("Usuario no existe");
  }
  removeUserUpdateLoading(dispatch);
};
