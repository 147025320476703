import { resetTravelInsuranceStateAction } from "./travelInsuranceDuck";
import { resetTransportationStateAction } from "./transportationDuck";
import { resetAirTicketStateAction } from "./airTicketDuck";
import { resetCarRentalStateAction } from "./carRentalDuck";
import { resetHostingStateAction } from "./hostingDuck";
import { resetActivityStateAction } from "./activityDuck";

export const resetAllAgencyStateAction = () => async (dispatch) => {
  dispatch(resetTravelInsuranceStateAction());
  dispatch(resetTransportationStateAction());
  dispatch(resetAirTicketStateAction());
  dispatch(resetCarRentalStateAction());
  dispatch(resetActivityStateAction());
  dispatch(resetHostingStateAction());
};
