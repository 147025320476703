import React, { useEffect, Fragment, useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import {
  Container,
  Card,
  Modal,
  Button,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { getPackagesData } from "../../firebase/config";

const TravelCard = ({ imageUrl, title, description, handleShow }) => {
  return (
    <Card className="border-0">
      <Card.Img variant="top" src={imageUrl} alt={title} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>

        <Row className="m-1">
          <Button
            variant="outline-warning"
            className="fw-bold"
            onClick={handleShow}
          >
            Ver más
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

const TravelModal = ({ show, handleClose, packageData }) => {
  const { title, image, description, caption, items } = packageData;
  const sendWsspQuote = () => {
    const message = `Hola! Estoy interesado en una cotización del paquete de ${title} con los siguientes datos:
Fecha: 
Numero de adultos:
Numero de niños:
    `;

    const encodedMessage = encodeURIComponent(message);
    const messageURL = `https://api.whatsapp.com/send/?phone=593986478477&text=${encodedMessage}`;
    window.open(messageURL, "_blank");
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col className="text-center">
              <Image src={image} className="full-wrapper-image" alt={title} />
            </Col>
          </Row>

          <h4 className="mt-4">{description}</h4>

          {items && (
            <Fragment>
              <h5>Programa Incluye</h5>
              <ul>
                {items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Fragment>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="warning" onClick={handleClose}>
          Ver Mas Detalles
        </Button> */}

        <Button variant="warning" onClick={sendWsspQuote}>
          Obtener Cotización <IoLogoWhatsapp className="ms-1" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const TravelGallery = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [dataPackages, setDataPackages] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const tmpData = await getPackagesData();
      setDataPackages(tmpData);
    }
    fetchData();
  }, []);

  const handleClose = () => setModalShow(false);
  const handleShow = (packageData) => {
    setModalContent(packageData);
    setModalShow(true);
  };

  return (
    <Container className="mt-5 pt-5">
      <h2 className="mt-2 text-center main-text-color fw-bold">Tours</h2>
      <h3 className="text-center gray-text-color">Posibles Destinos</h3>

      <Row xs={1} sm={2} lg={4} className="g-4 mt-4">
        {dataPackages.map((pack, index) => (
          <Col key={index}>
            <TravelCard
              imageUrl={pack.image}
              title={pack.title}
              description={pack.caption}
              handleShow={() => handleShow(pack)}
            />
          </Col>
        ))}
      </Row>

      <TravelModal
        show={modalShow}
        handleClose={handleClose}
        packageData={modalContent}
      />
    </Container>
  );
};

export default TravelGallery;
