import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { getEmployeeInfoAction } from "../redux/ducks/agency/employeeDuck";
import { getAllChoicesAction } from "../redux/ducks/utilsDucks";
import { AppContextProvider } from "../utils/context";
import NavBar from "./NavBar";
import Footer from "./Footer";
import {
  checkAuthenticationAction,
  loadUserInfoAction,
  refershAuthenticationAction,
} from "../redux/ducks/authDucks";
import Loading from "../pages/Loading";

const Layout = ({
  children,
  checkAuthentication,
  loadUserInfo,
  agencyRuc,
  refershAuthentication,
  getEmployeeInfoAPI,
  getAllChoicesAPI,
  auth,
}) => {
  useEffect(() => {
    getAllChoicesAPI();
    const fetchData = async () => {
      // await refershAuthentication();
      // await checkAuthentication();
      await loadUserInfo();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (auth.user.isAdmin) getEmployeeInfoAPI(agencyRuc, auth.user.id);
  }, [auth]);

  return (
    <AppContextProvider>
      <ToastContainer
        autoClose={4000}
        rtl={false}
        newestOnTop
        draggable
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />

      {auth.isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <NavBar />
          <Container id="body-container" className="p-0" fluid>
            {children}
          </Container>
          <Footer />
        </Fragment>
      )}
    </AppContextProvider>
  );
};

const mapStateToProps = (state) => ({
  agencyRuc: state.agency.ruc,
  auth: state.auth,
});

const actions = {
  refershAuthentication: refershAuthenticationAction,
  checkAuthentication: checkAuthenticationAction,
  getEmployeeInfoAPI: getEmployeeInfoAction,
  getAllChoicesAPI: getAllChoicesAction,
  loadUserInfo: loadUserInfoAction,
};

export default connect(mapStateToProps, actions)(Layout);
