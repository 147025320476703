import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";

import authReducer from "./ducks/authDucks";
import userReducer from "./ducks/userDucks";
import utilsReducer from "./ducks/utilsDucks";
import passengersInfoReducer from "./ducks/admin/passensersInfoDucks";
import journeyInfoReducer from "./ducks/passenger/journeysInfoDucks";

// agency
import agencyServicesReducer from "./ducks/agency/serviceDucks";
import employeeReducer from "./ducks/agency/employeeDuck";
import agencyReducer from "./ducks/agency/agencyDuck";
import travelInsuranceReducer from "./ducks/agency/travelInsuranceDuck";
import activityReducer from "./ducks/agency/activityDuck";
import airTicketReducer from "./ducks/agency/airTicketDuck";
import carRentalReducer from "./ducks/agency/carRentalDuck";
import transportationReducer from "./ducks/agency/transportationDuck";
import hostingReducer from "./ducks/agency/hostingDuck";
import webQuoteReducer from "./ducks/agency/webQuoteDuck";

// journey
import designedPackageReducer from "./ducks/journey/designedPackageDuck";
import journeyReducer from "./ducks/journey/journeyDuck";
import invoiceReducer from "./ducks/journey/invoiceDuck";
import packageServiceReducer from "./ducks/journey/packageServiceDuck";

// Utils
import aiportReducer from "./ducks/utils/airportDuck";
import airlineReducer from "./ducks/utils/airlineDuck";
import luggageTypeReducer from "./ducks/utils/luggageTypeDuck";
import foodPlanReducer from "./ducks/utils/foodPlanDuck";
import carTypeReducer from "./ducks/utils/carTypeDuck";

// Payments
import paymentReducer from "./ducks/payment/paymentDuck";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  utils: utilsReducer,
  passengers: passengersInfoReducer,
  myJourneys: journeyInfoReducer,
  agency: agencyReducer,
  agencyServices: agencyServicesReducer,
  designedPackages: designedPackageReducer,
  journeys: journeyReducer,
  employee: employeeReducer,
  airport: aiportReducer,
  airline: airlineReducer,
  luggageType: luggageTypeReducer,
  foodPlan: foodPlanReducer,
  carType: carTypeReducer,
  travelInsurance: travelInsuranceReducer,
  activity: activityReducer,
  airTicket: airTicketReducer,
  carRental: carRentalReducer,
  transportation: transportationReducer,
  hosting: hostingReducer,
  payment: paymentReducer,
  invoice: invoiceReducer,
  packageService: packageServiceReducer,
  webQuote: webQuoteReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const middleware = applyMiddleware(thunk);
  const storeCompose = composeEnhancers(middleware);
  const store = createStore(rootReducer, storeCompose);
  return store;
}
