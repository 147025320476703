import React, { useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import {
  Container,
  Accordion,
  Col,
  Row,
  Button,
  Alert,
  Badge,
} from "react-bootstrap";
import TransportationForm from "../../admin/newJourney/services/TransportationForm";
import { openCoordsGoogleMaps } from "../../../utils/coordinates";
import ModalUpdateService from "../ModalUpdateService";
import { openPDFInTab } from "../../../utils/pdf";

/* eslint-disable camelcase */
const Transportation = ({ data, isAdmin, serviceDirections }) => {
  if (!data) return null;
  const [showModalUpdateService, setShowModalUpdateService] = useState(false);
  const { id, status, service, travel_direction, service_name } = data;
  const {
    cost,
    reservation,
    boarding_time,
    arrival_time,
    operator,
    phone,
    capacity,
    luggage_type,
    start_location,
    destination,
  } = service || {};

  const serviceDirection = serviceDirections[travel_direction];
  const boardingTime = new Date(boarding_time);
  const arrivalTime = new Date(arrival_time);
  const locationStyle = { cursor: "pointer" };

  const locationButton = (locationData) => (
    <IoLocationSharp
      color="red"
      size={20}
      style={locationStyle}
      onClick={() =>
        openCoordsGoogleMaps(locationData.latitude, locationData.longitude)
      }
    />
  );

  return (
    <Accordion.Item eventKey={id}>
      {showModalUpdateService && (
        <ModalUpdateService
          show={showModalUpdateService}
          setShow={setShowModalUpdateService}
        >
          <TransportationForm dataInput={service} />
        </ModalUpdateService>
      )}

      <Accordion.Header>
        {service_name.name}
        <Badge bg="secondary" className="ms-2">
          {serviceDirection}
        </Badge>

        {status === "P" && (
          <Badge className="ms-2" bg="warning" style={{ color: "black" }}>
            Pendiente
          </Badge>
        )}
      </Accordion.Header>
      <Accordion.Body>
        {status === "P" && (
          <Alert variant="warning">
            Aun se encuentra gestionando la reversa. Si este proceso no culmina
            despues de 24H de haber hecho la compra, comuníquese con su asesor.
          </Alert>
        )}

        {service && (
          <Container className="p-0">
            <Row>
              <Col lg={10}>
                <Container className="p-0">
                  <Row className="mb-1">
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Operador:</strong> {operator}
                    </Col>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Contacto:</strong> {phone}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Capacidad:</strong> {capacity}
                    </Col>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Tipo de Equipaje:</strong> {luggage_type.name}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Hora Salida:</strong>{" "}
                      {boardingTime.toLocaleString()}
                    </Col>
                    <Col xs={12} lg={6} className="mb-2">
                      <strong>Hora Llegada:</strong>{" "}
                      {arrivalTime.toLocaleString()}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-1">
                      <strong>Recogida:</strong> {start_location.name}{" "}
                      {locationButton(start_location)}
                    </Col>
                    <Col xs={12} lg={6} className="mb-2">
                      <strong>Destino:</strong> {destination.name}{" "}
                      {locationButton(destination)}
                    </Col>
                  </Row>

                  {isAdmin && (
                    <Row>
                      <Col xs={12} lg={6} className="mb-1">
                        <strong>Costo:</strong> ${cost}
                      </Col>
                    </Row>
                  )}
                </Container>
              </Col>

              <Col>
                <Container>
                  <Row>
                    <Button
                      className="mb-2"
                      variant="warning"
                      onClick={() => openPDFInTab(reservation)}
                    >
                      Reservación
                    </Button>

                    {isAdmin && (
                      <Button
                        variant="warning"
                        onClick={() => setShowModalUpdateService(true)}
                      >
                        Actualizar
                      </Button>
                    )}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

/* eslint-enable camelcase */

export default Transportation;
