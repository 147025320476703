import axios from "axios";
import { toast } from "react-toastify";
import { dispatchAction } from "../../utils";
import { WEB_QUOTE_ENDPOINT } from "../../endPoints";

const data = {
  isLoading: false,
  creationSuccessful: false,
};

const CREATE_WEB_QUOTE_SUCCESS_SIGNAL = "CREATE_WEB_QUOTE_SUCCESS";
const CREATE_WEB_QUOTE_FAILED_SIGNAL = "CREATE_WEB_QUOTE_FAILED";

const SET_QUOTE_LOADING_SIGNAL = "SET_QUOTE_LOADING";
const REMOVE_QUOTE_LOADING_SIGNAL = "REMOVE_QUOTE_LOADING";

// eslint-disable-next-line default-param-last
export default function webQuoteReducer(state = data, action) {
  switch (action.type) {
    case CREATE_WEB_QUOTE_SUCCESS_SIGNAL:
      return { ...state, creationSuccessful: true };

    case SET_QUOTE_LOADING_SIGNAL:
      return { ...state, isLoading: true };

    case REMOVE_QUOTE_LOADING_SIGNAL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}

export const createWebQuoteAction = (payload) => async (dispatch) => {
  dispatch({ type: SET_QUOTE_LOADING_SIGNAL });
  try {
    const res = await axios.post(WEB_QUOTE_ENDPOINT, payload);
    if (res.status === 201) {
      toast.success("Cotización creada!");
      dispatchAction(dispatch, CREATE_WEB_QUOTE_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, CREATE_WEB_QUOTE_FAILED_SIGNAL);
    toast.error("Error al crear la Cotización");
  }
  dispatch({ type: REMOVE_QUOTE_LOADING_SIGNAL });
};
