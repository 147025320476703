import axios from "axios";
import { dispatchAction, getUserHeader } from "../../utils";
import { LUGGAGE_TYPE_ENDPOINT } from "../../endPoints";

const data = {
  all: [],
};

const GET_LUGGAGE_TYPES_SUCCESS_SIGNAL = "GET_LUGGAGE_TYPES_SUCCESS";
const GET_LUGGAGE_TYPES_FAILED_SIGNAL = "GET_LUGGAGE_TYPES_FAILED";
const RESET_LUGGAGE_TYPES_STATE_SIGNAL = "RESET_LUGGAGE_TYPES_STATE_SIGNAL";

// eslint-disable-next-line default-param-last
export default function luggageTypeReducer(state = data, action) {
  switch (action.type) {
    case GET_LUGGAGE_TYPES_SUCCESS_SIGNAL:
      return { ...state, all: action.payload };

    default:
      return state;
  }
}

export const resetLuggageTypeStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_LUGGAGE_TYPES_STATE_SIGNAL);
};

export const getLuggageTypesAction = () => async (dispatch) => {
  try {
    const config = getUserHeader();
    const res = await axios.get(LUGGAGE_TYPE_ENDPOINT, config);
    dispatchAction(dispatch, GET_LUGGAGE_TYPES_SUCCESS_SIGNAL, res.data);
  } catch (error) {
    dispatchAction(dispatch, GET_LUGGAGE_TYPES_FAILED_SIGNAL);
    console.error(error);
  }
};
