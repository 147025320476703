// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  appId: process.env.REACT_APP_appId,
  authDomain: process.env.REACT_APP_authDomain,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  projectId: process.env.REACT_APP_projectId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export async function getWebAppDataFromDocId(docId) {
  try {
    const webDocRef = doc(db, "web", docId);
    const webDocSnap = await getDoc(webDocRef);
    if (webDocSnap.exists()) {
      return webDocSnap.data();
    }
    console.error("No such document!");
  } catch (error) {
    console.error("Error getting document:", error);
    return null;
    // throw error;
  }
  return null;
}

export async function getCoverImages() {
  const data = await getWebAppDataFromDocId("Images_portadas");
  if (data !== null) return data;
  return { imagesURLs: [] };
}

export async function getImagesSections() {
  const data = await getWebAppDataFromDocId("ImagesSections");
  if (data !== null) return data;
  return { aboutUs: "" };
}

export async function getPackagesData() {
  try {
    const q = query(collection(db, "packages"), where("active", "==", true));
    const snapshot = await getDocs(q);
    const documents = snapshot.docs.map((d) => d.data());
    return documents;
  } catch (error) {
    console.error("Error getting document:", error);
    return [];
    // throw error;
  }
}
