const HOST = process.env.REACT_APP_API_HOST || "http://127.0.0.1:8000";
const API_HOST = `${HOST}/api/v1`;
const AGENCY_RUC = "0993385802001";

// Authentitacion
export const AUTH_ENDPOINT = `${HOST}/auth/`;
export const SINGUP_ENDPOINT = `${HOST}/auth/users/`;
export const USER_ACTIVATION_ENDPOINT = `${HOST}/auth/users/activation/`;
export const GET_USER_INFO_ENDPOINT = `${HOST}/auth/users/me/`;
export const GET_USER_TOKEN_ENDPOINT = `${HOST}/auth/jwt/create/`;
export const CHECK_AUTHENTITATION_ENDPOINT = `${HOST}/auth/jwt/verify/`;
export const REFRESS_ACCESS_TOKEN_ENDPOINT = `${HOST}/auth/jwt/refresh/`;
export const RESET_PASSWORD_ENDPOINT = `${HOST}/auth/users/reset_password/`;
export const RESET_PASSWORD_CONFIRM_ENDPOINT = `${HOST}/auth/users/reset_password_confirm/`;
export const CHANGE_PASSWORD_ENDPOINT = `${HOST}/auth/users/set_password/`;
export const EMAIL_BY_USER_ENDPOINT = `${API_HOST}/user/email_by_user`;

// Models Endpoints
export const PERSON_ENDPOINT = `${API_HOST}/user/person/`;
export const ID_PHOTOS_ENDPOINT = `${API_HOST}/user/passenger/id_photo/`;
export const USER_CONTACT_ENDPOINT = `${API_HOST}/user/contact/`;
export const EMERGENCY_CONTACT_ENDPOINT = `${API_HOST}/user/emergency_contact/`;
export const USER_ENDPOINT = `${API_HOST}/user/user/`;

// Personal Data
export const PERSONAL_DATA_ENDPOINT = `${API_HOST}/user/person/information`;
export const PERSONAL_CONTACTS_ENDPOINT = `${API_HOST}/user/person/contacts`;

// Admin
export const JOURNEYS_BY_AGENCY_ENDPOINT = `${API_HOST}/journey/journeys_by_agency`;
export const PASSENGERS_INFO_BY_AGENCY_ENDPOINT = `${API_HOST}/agency/passengers_by_agency/${AGENCY_RUC}`;
export const JOURNEYS_BY_PASSENGERS_BY_AGENCY_ENDPOINT = `${API_HOST}/agency/journeys_by_passengers_by_agency/${AGENCY_RUC}`;
export const PASSENGERS_IMAGES_ENDPOINT = `${API_HOST}/user/id_photos/`;
export const PASSENGER_ENDPOINT = `${API_HOST}/user/passenger/${AGENCY_RUC}`;

// Passenger
export const MY_JOURNEYS_INFO_ENDPOINT = `${API_HOST}/journey/journeys_by_passenger`;

// Utils
export const AIRPORTS_BY_COUNTRY_ENDPOINT = `${API_HOST}/utils/airports_by_country`;
export const AIRPORTS_BY_CITY_ENDPOINT = `${API_HOST}/utils/airports_by_city`;
export const CITIES_BY_COUNTRY_ENDPOINT = `${API_HOST}/utils/cities_by_countries`;
export const ALL_COUNTRIES_ENDPOINT = `${API_HOST}/utils/all_countries`;
export const PERSON_CHOICES_ENDPOINT = `${API_HOST}/utils/choices`;
export const LUGGAGE_TYPE_ENDPOINT = `${API_HOST}/utils/luggage_types`;
export const AIRLINE_ENDPOINT = `${API_HOST}/utils/airline`;
export const FOOD_PLAN_ENDPOINT = `${API_HOST}/utils/food_plan`;
export const CAR_TYPE_ENDPOINT = `${API_HOST}/utils/car_type`;
export const COORDINATE_ENDPOINT = `${API_HOST}/utils/coordinate/`;

// journeys
export const DESINGED_PACKAGE_ENDPOINT = `${API_HOST}/journey/designed_package`;
export const SERVICES_BY_DESINGED_PACKAGE_ENDPOINT = `${API_HOST}/journey/services_by_designed_package`;
export const INVOICE_ENDPOINT = `${API_HOST}/journey/invoice/`;
export const PACKAGE_SERVICE_ENDPOINT = `${API_HOST}/journey/package_service/`;

// agency
export const AGENCY_SERVICES_ENDPOINT = `${API_HOST}/agency/services_name`;
export const EMPLOYEE_ENDPOINT = `${API_HOST}/agency/employee`;
export const AIR_TICKET_ENDPOINT = `${API_HOST}/agency/air_ticket/`;
export const TRANSPORTATION_ENDPOINT = `${API_HOST}/agency/transportation/`;
export const HOSTING_ENDPOINT = `${API_HOST}/agency/hosting/`;
export const ACTIVITY_ENDPOINT = `${API_HOST}/agency/activity/`;
export const CAR_RENTAL_ENDPOINT = `${API_HOST}/agency/car_rental/`;
export const TRAVEL_INSURANCE_ENDPOINT = `${API_HOST}/agency/travel_insurance/`;
export const WEB_QUOTE_ENDPOINT = `${API_HOST}/agency/web_quote/`;

// Payment
export const PAYMENT_ENDPOINT = `${API_HOST}/payment/payment/`;
