import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import {
  createActivityAction,
  updateActivityAction,
} from "../../../../redux/ducks/agency/activityDuck";
import { validateDateGTToday } from "../../../../utils/validators";

const ActivityForm = ({
  updateActivityAPI,
  createActivityAPI,
  activityState,
  foodPlans,
  agencyRuc,
  dataInput = null,
  packageServiceID = null,
}) => {
  const { created, updated } = activityState;
  const updateTask = dataInput !== null;
  const blockRestrictedFields = updateTask;
  const blockAllFields = (created || updated) !== null;
  const buttonName = updateTask ? "Actualizar" : "Guardar";
  const commonData = { agency: agencyRuc };
  const defaultData = {
    cost: 0,
    name: "",
    description: "",
    start_date: "",
    end_date: "",
    food_plan: "",
    ...commonData,
  };
  const [data, setData] = useState(defaultData);
  const [validated, setValidated] = useState(false);
  const [dataSended, setDataSended] = useState(false);

  const validatedFiedls = {
    name: data.name !== "",
    description: data.description !== "",
    start_date: validateDateGTToday(data.start_date) || updateTask,
    end_date: validateDateGTToday(data.end_date) || updateTask,
    food_plan: data.food_plan !== "",
    reservation: Boolean(data.reservation) || updateTask,
    cost: data.cost >= 0,
  };
  const isFormValidated = Object.values(validatedFiedls).every((val) => val);

  useEffect(() => {
    if (updateTask) {
      const tmpData = { ...dataInput, ...commonData };
      tmpData.food_plan = tmpData.food_plan.id;
      delete tmpData.reservation;
      setData(tmpData);
    }
  }, [dataInput]);

  const handleAddReservationFile = (event) => {
    const file = event.target.files[0];
    if (file) setData({ ...data, reservation: file });
  };

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const sendData = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDataSended(true);

    const form = event.currentTarget;
    const formIsValid = form.checkValidity();
    if (!(isFormValidated && formIsValid) && !updateTask) return;
    setValidated(true);

    if (updateTask) updateActivityAPI(data);
    else createActivityAPI(data, packageServiceID);
  };

  return (
    <Form noValidate validated={validated} onSubmit={sendData}>
      <Container>
        <Row>
          <Col sm={12} lg={6} className="mb-4">
            <Form.Group controlId="formName">
              <Form.Label>Título</Form.Label>
              <Form.Control
                name="name"
                value={data.name}
                onChange={handleInputChange}
                disabled={blockRestrictedFields || blockAllFields}
                isInvalid={dataSended && !validatedFiedls.name}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={6} className="mb-4">
            <Form.Group controlId="formDescription">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                as="textarea"
                onChange={handleInputChange}
                value={data.description}
                name="description"
                isInvalid={dataSended && !validatedFiedls.description}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formFoodPlan">
              <Form.Label>Plan Alimenticio</Form.Label>
              <Form.Select
                disabled={blockAllFields}
                onChange={handleInputChange}
                value={data.food_plan}
                name="food_plan"
                isInvalid={dataSended && !validatedFiedls.food_plan}
                required
              >
                <option>Seleccione</option>
                {foodPlans.map((foodPlan) => (
                  <option key={foodPlan.id} value={foodPlan.id}>
                    {foodPlan.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formStartDate">
              <Form.Label>Fecha Inicio</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                onChange={handleInputChange}
                value={data.start_date}
                name="start_date"
                isInvalid={dataSended && !validatedFiedls.start_date}
              />
              <Form.Control.Feedback type="invalid">
                La fecha debe ser mayor o igual al día de hoy
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4">
            <Form.Group controlId="formEndDate">
              <Form.Label>Fecha Fin</Form.Label>
              <Form.Control
                disabled={blockAllFields}
                type="date"
                onChange={handleInputChange}
                value={data.end_date}
                name="end_date"
                isInvalid={dataSended && !validatedFiedls.end_date}
              />
              <Form.Control.Feedback type="invalid">
                La fecha debe ser mayor o igual al día de hoy
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={12} lg={6} className="mb-4 mb-lg-2">
            <Form.Group controlId="formReservation">
              <Form.Label>Reserva</Form.Label>
              <Form.Control
                onChange={handleAddReservationFile}
                disabled={blockAllFields}
                type="file"
                required
                isInvalid={dataSended && !validatedFiedls.reservation}
              />
            </Form.Group>
          </Col>

          <Col sm={12} lg={2} className="mb-4 mb-lg-2">
            <Form.Group controlId="formCost">
              <Form.Label>Costo</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  disabled={blockAllFields}
                  type="number"
                  onChange={handleInputChange}
                  value={data.cost}
                  name="cost"
                  isInvalid={dataSended && !validatedFiedls.cost}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} lg={{ offset: 2, span: 2 }} className="mt-4">
            <Button
              className="w-100"
              variant="warning"
              type="submit"
              disabled={blockAllFields}
            >
              {buttonName}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  foodPlans: state.foodPlan.all,
  activityState: state.activity,
  agencyRuc: state.agency.ruc,
});

const actions = {
  createActivityAPI: createActivityAction,
  updateActivityAPI: updateActivityAction,
};

export default connect(mapStateToProps, actions)(ActivityForm);
