export const VIEW_URL = "https://www.epicjourneyagency.com";

export const HomePath = "/home";
export const ContactsPath = "/contacts";
export const LoginPath = "/login";
export const AdminPath = "/admin";
export const PassengerInformationPath = "/passenger_information";
export const ImportantInformationPath = "/important_information";
export const QandAPath = "/questions_and_answers";
export const AboutUsPath = "/about_us";
export const TermsAndConditionsPath = "/terms_and_conditions";
export const PrivacyPolicyPath = "/privacy_policy";

// Admin
export const NewPassengerPath = "/admin/new_passenger";
export const NewJourneyPath = "/admin/new_journey";
export const PassengersPath = "/admin/passengers";
export const CalendarPath = "/admin/calendar";
export const PackagePath = "/admin/packages";

// auth & user
export const ActivateUserPath = "/user/activate/:uid/:token";
export const ResetPasswordPath = "/user/reset_password";
export const ResetPasswordConfirmPath =
  "/user/password/reset/confirm/:uid/:token";
export const UpdateUserDataPath = "/user/update";
export const UserInfoPath = "/user/information";
export const ChangePasswordPath = "/user/change_password";
export const ReportProblemPath = "/user/report_problem";
