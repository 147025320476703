import axios from "axios";
import { toast } from "react-toastify";
import { dispatchAction, getUserHeader, getFormData } from "../../utils";
import { INVOICE_ENDPOINT } from "../../endPoints";

const data = {
  created: null,
  updated: null,
};

const CREATE_INVOICE_SUCCESS_SIGNAL = "CREATE_INVOICE_SUCCESS";
const CREATE_INVOICE_FAILED_SIGNAL = "CREATE_INVOICE_FAILED";
const RESET_INVOICE_STATE_SIGNAL = "RESET_INVOICE_STATE_SIGNAL";

const UPDATE_INVOICE_SUCCESS_SIGNAL = "UPDATE_INVOICE_SUCCESS";
const UPDATE_INVOICE_FAILED_SIGNAL = "UPDATE_INVOICE_FAILED";

// eslint-disable-next-line default-param-last
export default function invoiceReducer(state = data, action) {
  switch (action.type) {
    case CREATE_INVOICE_SUCCESS_SIGNAL:
      return { ...state, created: action.payload };

    case UPDATE_INVOICE_SUCCESS_SIGNAL:
      return { ...state, updated: action.payload };

    case RESET_INVOICE_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetInvoiceStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_INVOICE_STATE_SIGNAL);
};

export const createInvoiceAction = (payload) => async (dispatch) => {
  try {
    const formData = getFormData(payload);
    const config = getUserHeader({ addFormData: true });
    const res = await axios.post(INVOICE_ENDPOINT, formData, config);
    if (res.status === 201) {
      toast.success("Factura creada");
      dispatchAction(dispatch, CREATE_INVOICE_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, CREATE_INVOICE_FAILED_SIGNAL);
    toast.error("Error al crear la Factura");
  }
};

export const updateInvoiceAction = (payload) => async (dispatch) => {
  try {
    const formData = getFormData(payload);
    const endpoint = `${INVOICE_ENDPOINT}${payload.id}/`;
    const config = getUserHeader({ addFormData: true });
    const res = await axios.patch(endpoint, formData, config);
    if (res.status === 200) {
      toast.success("Factura Actualizada");
      dispatchAction(dispatch, UPDATE_INVOICE_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, UPDATE_INVOICE_FAILED_SIGNAL);
    toast.error("Error al actualizar la Factura");
  }
};
