import axios from "axios";
import { toast } from "react-toastify";
import { updateToCompletePackageServiceAction } from "../journey/packageServiceDuck";
import { dispatchAction, getUserHeader, getFormData } from "../../utils";
import { TRANSPORTATION_ENDPOINT } from "../../endPoints";

const data = {
  created: null,
  updated: null,
};

const CREATE_TRANSPORTATION_SUCCESS_SIGNAL = "CREATE_TRANSPORTATION_SUCCESS";
const CREATE_TRANSPORTATION_FAILED_SIGNAL = "CREATE_TRANSPORTATION_FAILED";
const RESET_TRANSPORTATION_STATE_SIGNAL = "RESET_TRANSPORTATION_STATE_SIGNAL";

const UPDATE_TRANSPORTATION_SUCCESS_SIGNAL = "UPDATE_TRANSPORTATION_SUCCESS";
const UPDATE_TRANSPORTATION_FAILED_SIGNAL = "UPDATE_TRANSPORTATION_FAILED";

// eslint-disable-next-line default-param-last
export default function transportationReducer(state = data, action) {
  switch (action.type) {
    case CREATE_TRANSPORTATION_SUCCESS_SIGNAL:
      return { ...state, created: action.payload };

    case UPDATE_TRANSPORTATION_SUCCESS_SIGNAL:
      return { ...state, updated: action.payload };

    case RESET_TRANSPORTATION_STATE_SIGNAL:
      return data;

    default:
      return state;
  }
}

export const resetTransportationStateAction = () => async (dispatch) => {
  dispatchAction(dispatch, RESET_TRANSPORTATION_STATE_SIGNAL);
};

export const createTransportationAction =
  (payload, packageServiceID) => async (dispatch) => {
    try {
      const formData = getFormData(payload);
      const config = getUserHeader({ addFormData: true });
      const res = await axios.post(TRANSPORTATION_ENDPOINT, formData, config);
      if (res.status === 201) {
        toast.success("Transporte creado");
        dispatchAction(
          dispatch,
          CREATE_TRANSPORTATION_SUCCESS_SIGNAL,
          res.data
        );
        if (packageServiceID)
          dispatch(
            updateToCompletePackageServiceAction(packageServiceID, res.data.id)
          );
      }
    } catch (error) {
      dispatchAction(dispatch, CREATE_TRANSPORTATION_FAILED_SIGNAL);
      toast.error("Error al crear el Transporte");
    }
  };

export const updateTransportationAction = (payload) => async (dispatch) => {
  try {
    const formData = getFormData(payload);
    const endpoint = `${TRANSPORTATION_ENDPOINT}${payload.id}/`;
    const config = getUserHeader({ addFormData: true });
    const res = await axios.patch(endpoint, formData, config);
    if (res.status === 200) {
      toast.success("Transporte Actualizado");
      dispatchAction(dispatch, UPDATE_TRANSPORTATION_SUCCESS_SIGNAL, res.data);
    }
  } catch (error) {
    dispatchAction(dispatch, UPDATE_TRANSPORTATION_FAILED_SIGNAL);
    toast.error("Error al actualizar el Transporte");
  }
};
