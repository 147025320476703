import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { LoginPath, PassengerInformationPath } from "../../utils/paths";

/* eslint-disable no-else-return */
const AdminRoute = ({ auth }) => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  const component = () => {
    if (auth.access_token === null) return <Navigate to={LoginPath} />;

    if (!auth.isLoading && auth.isAuth) {
      if (auth.isAuth) {
        if (auth.user.isAdmin) return <Outlet />;
        else return <Navigate to={PassengerInformationPath} />;
      }
      return <Navigate to={LoginPath} />;
    }
    return null;
  };

  return component();
};
/* eslint-enable no-else-return */

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminRoute);
