import React, { Fragment } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { OverlayTrigger, Tooltip, Alert, Button, Badge } from "react-bootstrap";

export const infoOverlay = (data) => (
  <OverlayTrigger overlay={<Tooltip>{data}</Tooltip>}>
    <span style={{ cursor: "pointer" }}>
      <FaInfoCircle className="ms-1" />
    </span>
  </OverlayTrigger>
);

export const pendingInfo = (isAdmin, onClick) => (
  <Fragment>
    <Alert variant="warning">
      Aún se encuentra gestionando su reversa. Si este proceso no culmina
      despues de 24h de la compra, comuníquese con su asesor.
    </Alert>

    {isAdmin && (
      <Button variant="warning" onClick={onClick}>
        Crear
      </Button>
    )}
  </Fragment>
);

export const pendingHeader = () => (
  <Badge className="ms-2" bg="warning" style={{ color: "black" }}>
    Pendiente
  </Badge>
);
